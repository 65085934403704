<template>
	<div>
		<nav2></nav2>
		<banner2  :bannerImg="Img1" :one="t('title')"></banner2>
		<div class="fixed-width-container container row" style="margin-top: 50px;">
			<div class="col-12">
				<h1 style="text-align: center;" class="display-6">{{t("title")}}</h1>
			</div>
			<div style="margin-top: 35px" class="row">
				<div>

					<p class="text-bold">
						{{t("h1")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/4-1.png" height="450" width="800" />
					</p>
					<p class="text-bold">
						{{t("h2")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/4-2.png" height="450" width="800" />
					</p>

					<p class="text-bold">
						{{t("h3")}}
					</p>





				</div>
			</div>
		</div>
		<div style="padding: 25px;"></div>
		<footer2></footer2>
	</div>
</template>

<script setup>
	import footer2 from "../../components/footer.vue";
	import banner2 from "../../components/banner.vue";
	import nav2 from "../../components/nav.vue";

	export default {
		name: "index",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
		},
		data() {
			return {
				Img1: require("../../assets/img/keji/矿山banner1.png"),
				content: {
					title: {
						zh: "赞比亚总统出席“万村通”项目启动模式",
						en: "The President of Zambia attends the launch of the \"Ten Thousand Villages\" project"
					},
					h1: {
						zh: "中非合作项目“万村通”，旨在让非洲国家1万个村庄收看到卫星数字电视。经过多年不懈努力，这个规模宏大的项目正在接近实现既定目标，为千万非洲民众打开了解外界信息的窗口。",
						en: "The China-Africa cooperation project, “Ten Thousand Villages ”, aims to enable 10,000 villages in African countries to receive satellite digital television. After years of unremitting efforts, this ambitious project is close to realizing its set goals, opening a window to the outside world for millions of African people."
					},
					h2: {
						zh: "“万村通”不仅让村民“通”世界，项目的实施还为当地培养广播电视节目人才，使他们掌握卫星电视的业务知识、安装和售后服务技能，不仅为项目后续运营提供人力保障，也为非洲地区提供了就业机会，促进相关产业的发展。",
						en: "The “Ten Thousand Villages” project not only allows villagers to “access” the world, but also cultivates local radio and TV program talents to master the business knowledge, installation and after-sales service skills of satellite TV, which not only provides manpower protection for the subsequent operation of the project, but also provides job opportunities for the development of related industries in Africa. This not only provides manpower for the subsequent operation of the project, but also provides employment opportunities in Africa and promotes the development of related industries."
					},
					h3: {
						zh: "该项目启动仪式，译联社很荣幸邀约了赞比亚当地的政府官员出席开幕仪式。",
						en: "For the launch of this project, Translation Associates was honored to invite local Zambian government officials to attend the opening ceremony."
					},


				},
				language: "en",

			};
		},
		created() {
			this.language = this.$i18n.getLocale();
		},
		methods: {
			t(res) {
				return this.content[res][this.language];
			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.fixed-width-container {
		width: 800px !important;
		/* 设置固定宽度 */
		max-width: 100%;
		/* 确保在小屏幕上宽度占满容器 */
		margin: 0 auto;
		/* 水平居中 */
	}

	p {
		margin-top: 10px;
	}

	p.text-bold {
		margin-top: 25px;
	}
</style>
