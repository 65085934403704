import Vue from 'vue'
import Vuex from 'vuex'
import {
	messages
} from "../messages/messages";

Vue.use(Vuex)

export const i18n = {

	setLocale(locale) {
		//this.locale = locale;
		localStorage.setItem("locale", locale);

	},
	getLocale() {
		var res = localStorage.getItem('locale');
		if(res){
			return localStorage.getItem('locale');
		}else{
			localStorage.setItem("locale", 'en');
			return 'en';
		}


		
	},
	t(key) {
		let locale = localStorage.getItem('locale');

		if (!locale) {
			locale = 'en';
		}

		const message = messages[locale].message;
		return message[key] || key;
	}
};
export default new Vuex.Store({
	state: {
		title: 'EUPWIRE',
		pageCount: 8,
		selected: 0,
	},
	mutations: {},
	actions: {},
	modules: {}
})
