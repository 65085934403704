<template>
	<div>
		<nav2></nav2>
		<banner2 :bannerImg="Img1" :one="t('title')"></banner2>
		<div class="fixed-width-container container row" style="margin-top: 50px;">
			<div class="col-12">
				<h1 style="text-align: center;" class="display-6">{{t("title")}}</h1>
			</div>
			<div style="margin-top: 35px" class="row">
				<div>


					<p class="">
						<span class="text-bold"> {{t("h1_t1")}}</span>{{t("h1_t2")}}
					</p>

					<p class="text-bold">
						{{t("h2_t1")}}
					</p>
					<p class="">
						{{t("h2_t2")}}
					</p>
					<p class="">
						{{t("h2_t3")}}
					</p>
					<p class="">
						{{t("h2_t4")}}
					</p>
					<p class="">
						{{t("h2_t5")}}
					</p>
					
					
					<p class="text-bold">
						{{t("h3_t1")}}
					</p>
					<p class="">
						{{t("h3_t2")}} <span style="font-size: 24px; font-weight: 900;">{{t("h3_t22")}}</span>
					</p>
					<p class="">
						{{t("h3_t3")}}
					</p>
					<p class="">
						{{t("h3_t4")}}
					</p>
					
					
					<p class="text-bold">
						{{t("h4_t1")}}
					</p>
					<p class="">
						{{t("h4_t2")}}
					</p>
					
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/2-2.png" width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/2-3.png" width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/2-4.png" width="800" />
					</p>





				</div>
			</div>
		</div>
		<div style="padding: 25px;"></div>
		<footer2></footer2>
	</div>
</template>

<script setup>
	import footer2 from "../../components/footer.vue";
	import banner2 from "../../components/banner.vue";
	import nav2 from "../../components/nav.vue";

	export default {
		name: "index",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
		},
		data() {
			return {
				Img1: require("../../assets/img/keji/矿山banner1.png"),
				content: {
					title: {
						zh: "美的智能绿色能源管理解决方案MHELIOS发布，推动更智能、更绿色的生活方式",
						en: "Midea's Smart Green Energy Management Solution MHELIOS Released to Promote Smarter, Greener Lifestyle"
					},
					h1_t1: {
						zh: "客户名称：",
						en: "Customer Name:",
					},
					h1_t2: {
						zh: "美的集团",
						en: "Midea Group",
					},


					h2_t1: {
						zh: "客户需求：",
						en: "client need:",
					},
					h2_t2: {
						zh: "1、泰国发布会记者邀约",
						en: "1. Invitation of journalists for the launch in Thailand",
					},
					h2_t3: {
						zh: "2、现场记者专访",
						en: "2. Interviews with on-site journalists",
					},
					h2_t4: {
						zh: "3、法新社媒体发布",
						en: "3. AFP media release",
					},
					h2_t5: {
						zh: "4、雅虎财经、Bezinga等垂直财经门户通投",
						en: "4. Yahoo Finance, Bezinga and other vertical financial portals through the investment",
					},


					h3_t1: {
						zh: "传播策略：",
						en: "Communication strategy:",
					},
					h3_t2: {
						zh: "1、新品发布会活动：从产品供给侧突显美的",
						en: "1.New product launch event: highlighting the beauty from the product supply side",
					},
					h3_t22: {
						zh: "MHELIOS产品优势",
						en: "MHELIOS Product Advantages",
					},
					h3_t3: {
						zh: "2、现场采访： 法新社知名记者现出席芭提雅活动，现场采访研发部负责人",
						en: "2.On-site Interview: AFP famous reporter now attends the Pattaya event and interviews the person in charge of R&D on site.",
					},
					h3_t4: {
						zh: "3、200+媒体活动后同步报道",
						en: "3. 200+ media synchronized coverage after the event",
					},

					h4_t1: {
						zh: "传播结果：",
						en: "Dissemination of results:",
					},
					h4_t2: {
						zh: "总计投放媒体300+ ，谷歌搜索有较好的收录，美的光伏新品发布成功",
						en: "A total of 300+ media placement, Google search has a good index, Midea PV new product release success!",
					}


				},
				language: "en",

			};
		},
		created() {
			this.language = this.$i18n.getLocale();
		},
		methods: {
			t(res) {
				return this.content[res][this.language];
			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.fixed-width-container {
		width: 800px !important;
		/* 设置固定宽度 */
		max-width: 100%;
		/* 确保在小屏幕上宽度占满容器 */
		margin: 0 auto;
		/* 水平居中 */
	}

	p {
		margin-top: 10px;
	}

	p.text-bold {
		margin-top: 25px;
	}
</style>
