<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2  :bannerImg="Img1" :one="$i18n.t('titleThree_1')"></banner2>
		<!-- 图左文字右，带副标题 -->
		<div class="box">
			<imgAndText :dWidth="1500" disType="flex">
				<!-- 文本插槽，文本放在这个标签内 -->
				<template v-slot:titleSlot>
					<!-- 可以通过再套个div复写样式 -->
					<div class="titleSlot">{{ $i18n.t("titleThree_1") }}{{ $i18n.t("fw") }}</div>
				</template>
				<!-- 文本插槽，文本放在这个标签内 -->
				<template v-slot:textSlot>
					<!-- 还可以再增加副标题 -->
					<div class="textTitle">
						<div class="subTextTitle" style="visibility: hidden;">这是副标题</div>
						<div class="textContent">{{ $i18n.t("titleThree_1_list1_text") }}</div>
					</div>
				</template>
				<!-- 图片插槽，图片放在这个标签内 -->

				<template v-slot:imgSlot>
					<!-- 图片与文字的间距通过图片的margin控制 -->
					<img style="height: 300px;margin-right: 100px;" src="../assets/img/newsDetail/2222.png" alt="" />
				</template>
			</imgAndText>
		</div>
		<!-- 图右文字左，不带副标题，内容居中 -->
		<div class="box" style="background-color: #fff;">
			<imgAndText :dWidth="1250" disType="flexE">
				<template v-slot:titleSlot>
					<div class="titleSlot">{{ $i18n.t("titleThree_2") }}{{ $i18n.t("fw") }}</div>
				</template>
				<template v-slot:textSlot>
					<div class="textTitle alignItemsCenter">
						<!-- 想放两排也可以，居中显示 -->
						<div class="subTextTitle" style="visibility: hidden;">这是副标题</div>
						<div class="textContent">{{ $i18n.t("titleThree_2_list1_text") }}</div>
					</div>
				</template>

				<template v-slot:imgSlot>
					<img style="height: 300px;margin-left: 100px;" src="../assets/img/2.png" alt="" />
				</template>
			</imgAndText>
		</div>
		<div class="box">
			<imgAndText :dWidth="1500" disType="flex">
				<!-- 文本插槽，文本放在这个标签内 -->
				<template v-slot:titleSlot>
					<!-- 可以通过再套个div复写样式 -->
					<div class="titleSlot">{{ $i18n.t("titleThree_3") }}{{ $i18n.t("fw") }}</div>
				</template>
				<!-- 文本插槽，文本放在这个标签内 -->
				<template v-slot:textSlot>
					<!-- 还可以再增加副标题 -->
					<div class="textTitle">
						<div class="subTextTitle" style="visibility: hidden;">这是副标题</div>
						<div class="textContent">{{ $i18n.t("titleThree_3_list1_text") }}</div>
					</div>
				</template>
				<!-- 图片插槽，图片放在这个标签内 -->
				<template v-slot:imgSlot>
					<!-- 图片与文字的间距通过图片的margin控制 -->
					<img style="height: 300px;margin-right: 100px;" src="../assets/img/3.jpg" alt="" />
				</template>
			</imgAndText>
		</div>
		<!-- 图右文字左，不带副标题，内容居中 -->
		<div class="box" style="background-color: #fff;">
			<imgAndText :dWidth="1250" disType="flexE">
				<template v-slot:titleSlot>
					<div class="titleSlot">{{ $i18n.t("titleThree_4") }}{{ $i18n.t("fw") }}</div>
				</template>

				<template v-slot:textSlot>
					<div class="textTitle alignItemsCenter">
						<!-- 想放两排也可以，居中显示 -->
						<div class="subTextTitle" style="visibility: hidden;">这是副标题</div>
						<div class="textContent">{{ $i18n.t("titleThree_4_list1_text") }}</div>
					</div>
				</template>

				<template v-slot:imgSlot>
					<img style="height: 300px;margin-left: 100px;" src="../assets/img/4.jpg" alt="" />
				</template>
			</imgAndText>
		</div>
		<div class="box">
			<imgAndText :dWidth="1500" disType="flex">
				<!-- 文本插槽，文本放在这个标签内 -->
				<template v-slot:titleSlot>
					<!-- 可以通过再套个div复写样式 -->
					<div class="titleSlot">{{ $i18n.t("titleThree_5") }}{{ $i18n.t("fw") }}</div>
				</template>
				<!-- 文本插槽，文本放在这个标签内 -->

				<template v-slot:textSlot>
					<!-- 还可以再增加副标题 -->
					<div class="textTitle">
						<div class="subTextTitle" style="visibility: hidden;">这是副标题</div>
						<div class="textContent">{{ $i18n.t("titleThree_5_list1_text") }}</div>
						<div class="textContent">{{ $i18n.t("titleThree_5_list2_text") }}</div>
						<div class="textContent">{{ $i18n.t("titleThree_5_list3_text") }}</div>
						<div class="textContent">{{ $i18n.t("titleThree_5_list4_text") }}</div>

					</div>
				</template>
				<!-- 图片插槽，图片放在这个标签内 -->
				<template v-slot:imgSlot>
					<!-- 图片与文字的间距通过图片的margin控制 -->
					<img style="height: 300px;margin-right: 100px;" src="../assets/img/1.jpg" alt="" />
				</template>
			</imgAndText>
		</div>
		<!-- 服务流程 -->
		<div class="box bs">
			<div class="flow">
				<p>服务流程</p>
				<!-- <div class="hx-box">
					<div class="hx"></div>
				</div> -->
				<div style="text-align:center">
					<img src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img6.jpg" alt="">
				</div>
			</div>
		</div>
		<!-- 我们的优势 -->
		<div class="box">
			<div class="flow">
				<p>我们的优势 </p>
				<!-- <div class="hx-box">
					<div class="hx"></div>
				</div> -->
				<div class="ys-box">
					<div class="ys">
						<div>
							<!-- <span style="font-size: 100px;font-weight: bold;
							font-family: emoji;">01</span> -->
							<img src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img3.jpg" alt=""
								srcset="">
						</div>
						<div>
							<img src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img4.jpg" alt=""
								srcset="">
						</div>
						<div>
							<img src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img5.jpg" alt=""
								srcset="">
						</div>
						<div>
							<img src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt=""
								srcset="">
						</div>
					</div>

				</div>
			</div>
		</div>

		<!-- 品牌案例 -->
		<div class="box">
			<div class="flow" style="margin-bottom: 40px;">
				<p style="margin-bottom: 35px; margin-top: 40px;">{{$i18n.t('brandCase')}} </p>
				<div class="al-box">
					<div class="al">
						<div @click="$router.push('/AnLi_1')">
							<img src="../assets/images/AnLi/1-1.jpg" alt="" srcset="" >
								<div>
									<div>{{$i18n.t('bc1_t')}}</div>
									<div>{{$i18n.t('bc1_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_2')">
							<img src="../assets/images/AnLi/2-1.jpg" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc2_t')}}</div>
									<div>{{$i18n.t('bc2_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_3')">
							<img src="../assets/images/AnLi/3-1.png" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc3_t')}}</div>
									<div>{{$i18n.t('bc3_b')}}</div>
								</div>
						</div>
					</div>
					<div class="al">
						<div @click="$router.push('/AnLi_4')">
							<img src="../assets/images/AnLi/4-1.png" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc4_t')}}</div>
									<div>{{$i18n.t('bc4_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_5')">
							<img src="../assets/images/AnLi/5-1.png" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc5_t')}}</div>
									<div>{{$i18n.t('bc5_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_6')">
							<img src="../assets/images/AnLi/6-1.jpg" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc6_t')}}</div>
									<div>{{$i18n.t('bc6_b')}}</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
// 引入组件
import imgAndText from "../components/models/imgAndText.vue";
// import flow from "../components/models/flow.vue";

export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		imgAndText,
		// flow
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../assets/img/keji/矿山banner1.png"),
			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},
	methods: {},
};
</script>

<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}

/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}

/*字体 文本居左*/
ul {
	list-style: none;
}

/*去ul li 小点*/
img {
	border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}

/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}

/*清浮动*/
a {
	color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}

/* 面包屑 */
.Crumbs {
	width: 1500px;
	height: 46px;
	margin: 0 auto;
}

.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}

.Crumbs div {
	line-height: 46px;
}

.Crumbs span {
	color: #000000;
}

/* 新闻详情 */
.NewsDetails_cont {
	width: 1500px;
	margin: 0 auto;
}

.NewsDetails_cont_title>h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}

.NewsDetails_cont_title img {
	vertical-align: middle;
}

.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}

.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}

.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}

.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}

.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}

.NewsDetails_cont_footer {
	padding: 0 40px;

	line-height: 68px;
	width: 1120px;
	height: 68px;
	background: #f8f8f8;
	display: flex;
	justify-content: space-between;
}

.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}

.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}

.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}

.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}

.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}

.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}

.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}

.box {
	display: flex;
	justify-content: center;
	background: #fafbff;
	padding-bottom: 40px;
}

.main {
	width: 1250px;
	padding-top: 60px;
}

.display-5 {
	font-size: 36px;
	font-weight: bold;
	line-height: 50px;
	text-align: center;
	color: #002459;
}

.info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 50px;
}

.info-text {
	padding-left: 53.2px;
	box-sizing: border-box;
	color: #666666;
	font-size: 16px;
	line-height: 27px;
}

.info-text p {
	font-size: 16px;
	line-height: 24px;
	color: #666666;
	margin-top: 30px;
}

.fwtitle {
	font-size: 30px;
	line-height: 60px;
	color: #333333;
	font-weight: bold;
	margin-top: -10px;
}

/* models */
.titleSlot {
	padding: 20px 0 40px;
}

.textTitle {
	box-sizing: border-box;
	color: #666666;
	font-size: 16px;
	line-height: 27px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.textTitle.textTitleCenter {
	justify-content: center;
}

.textTitle.alignItemsCenter {
	display: flex;
	align-items: center;
}

.textTitle .textContent {
	text-indent: 2em;
}

.subTextTitle {
	font-size: 20px;
	font-weight: 600;
	color: #333;
	margin: 15px 0 5px;
	text-align: center;
}

.box2 {
	column-gap: 100px;
}

.boxLeft {
	padding: 15px;
	border: 2px solid;
	border-image: linear-gradient(to right, #7094d3, #7094d36b) 1;
}

.boxRight {
	padding: 15px;
	border: 2px solid;
	border-image: linear-gradient(to right, #7094d36b, #7094d3) 1;
}


</style>
