<template>
	<div class="row reloadRow" style="background-color: #f7f8f9;">
		<div class="col-12  fixed-width-container container">
			<div class="top">
				<div class="logo">
					<div class="LogoImage">
						<img :src="logoImg" height="100" width="175" />
					</div>
					<!-- <div class="logoBox">
						<div class="LogoSpan d-none d-sm-block">
							<span>EupWire</span>
						</div>
						<div class="LogoSpan LogoSpanB d-none d-sm-block">
							<span>EupWire</span>
						</div>
					</div> -->
				</div>
				<div class="Language">
					<p>
						<span :style="$i18n.getLocale() == 'en'?'color: #000;':'color: #646464;'" style="text-align: center;width: 100px;" @click="changeLanguage('en')">EN</span> <span style="color: #646464;">/</span>
						<span :style="$i18n.getLocale() == 'zh'?'color: #000;':'color: #646464;'" style="text-align: center;width: 100px" @click="changeLanguage('zh')"> CN</span>
					</p>
				</div>
			</div>
			<nav class="navbar navbar-expand-lg navbar-light bg-light" style="font-family: 'Eras Medium ITC'">
				<div class="container-fluid" style="padding-left: 35px;">
					<a class="nav-link active under" style="font-size: 30rpx;font-weight: 700;" @click="funyuming('/')">{{ $i18n.t('titleOne') }}&nbsp;&nbsp;</a>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
						aria-label="Toggle navigation" style="margin-right: 10px;">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse under" id="navbarNavDropdown">
						<ul class="navbar-nav" style="font-size: 30rpx;font-weight: 700;">
							<li class="nav-item">
								<a class="nav-link active" aria-current="page"
									@click="funyuming('/titleTwo')">{{ $i18n.t('titleTwo')}}</a>
							</li>

							<!-- <li class="nav-item">
								<a class="nav-link active"
									@click="funyuming('/titleThree')">{{ $i18n.t('serviceInfo')}}</a>
							</li> -->

							<li class="nav-item dropdown">
								<a class="nav-link active dropdown-toggle" href="#" id="navbarDropdownMenuLink"
									role="button" data-bs-toggle="dropdown"
									aria-expanded="false">{{ $i18n.t('titleThree') }}</a>
								<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
									<li><a class="dropdown-item"
											@click="funyuming('/titleThree_1')">{{ $i18n.t('titleThree_1') }}</a></li>
									<li><a class="dropdown-item"
											@click="funyuming('/titleThree_2')">{{ $i18n.t('titleThree_2') }}</a></li>
									<li><a class="dropdown-item"
											@click="funyuming('/titleThree_3')">{{ $i18n.t('titleThree_3') }}</a></li>
									<li><a class="dropdown-item"
											@click="funyuming('/titleThree_4')">{{ $i18n.t('titleThree_4') }}</a></li>
									<li><a class="dropdown-item"
											@click="funyuming('/titleThree_5')">{{ $i18n.t('titleThree_5') }}</a></li>
									<!-- <li><a class="dropdown-item"
											@click="funyuming('/titleThree_6')">{{ $i18n.t('titleThree_6') }}</a></li> -->
								</ul>
							</li>


							<li class="nav-item">
								<a class="nav-link active"
									@click="funyuming('/titleFore')">{{ $i18n.t('titleFore')}}</a>
							</li>
							<li class="nav-item dropdown">
								<a class="nav-link active dropdown-toggle" href="#" id="navbarDropdownMenuLink"
									role="button" data-bs-toggle="dropdown"
									aria-expanded="false">{{ $i18n.t('titleFive') }}</a>
								<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
									<li><a class="dropdown-item"
											@click="funyuming('/titleFive_1')">{{ $i18n.t('titleFive_1') }}</a></li>
									<li><a class="dropdown-item"
											@click="funyuming('/titleFive_2')">{{ $i18n.t('titleFive_2') }}</a></li>
									<li><a class="dropdown-item"
											@click="funyuming('/titleFive_3')">{{ $i18n.t('titleFive_3') }}</a></li>
								</ul>
							</li>
							<li class="nav-item">
								<a class="nav-link active" @click="funyuming('/titleSix')">{{ $i18n.t('titleSix')}}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link active"
									@click="funyuming('/titleSeven')">{{ $i18n.t('titleSeven')}}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link active"
									@click="funyuming('/titleEight')">{{ $i18n.t('titleEight')}}</a>
							</li>
							<!-- <li class="nav-item">
								<a class="nav-link active"
									@click="funyuming('/titleNine')">{{ $i18n.t('titleNine')}}</a>
							</li> -->
						</ul>
					</div>
				</div>
			</nav>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	export default {
		computed: {
			//
		},
		mounted() {
			$(document).ready(function() {
				$(".hideThree").hide()
				$(".shoeThree").hover(function() {
					$(".hideThree").show()
				}, function() {
					$(".hideThree").hide()
				})
			})
		},
		name: "Nav",
		data() {
			return {
				// currentLang: "zh",
				logoImg: require("../assets/images/Logo.png"),
			};
		},
		methods: {
			changeLanguage(locale) {
				this.$i18n.setLocale(locale);
				window.location.reload();
				//this.$forceUpdate();
			},
			funyuming(msg) {
				this.$router.push(msg);
			},
		},
	};
</script>
<style >
	* {
		padding: 0px;
		margin: 0px;
	}

	.nav-item{
		padding-left: 1em;
		padding-right: 1em;
	}

	.reloadRow {
		/* 解决菜单乱动的问题 */
		width: 100vw;
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 0;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(-1* var(--bs-gutter-y));
		margin-right: calc(-.5* var(--bs-gutter-x));
		margin-left: calc(-.5* var(--bs-gutter-x));
	}

	.top {
		width: 100%;
		height: 80px;
		margin: 0 auto;
	}

	.logo {
		margin-left: 5px;
		height: 80px;
		float: left;
	}

	.LogoImage {
		width: 140px;
		float: left;
	}

  .fixed-width-container {
    width: 1200px !important; /* 设置固定宽度 */
    max-width: 100%; /* 确保在小屏幕上宽度占满容器 */
    margin: 0 auto; /* 水平居中 */
  }
	.Language {
		padding-top: 20px;
		width: 110px;
		height: 60px;
		float: right;
		margin-right: 10px;
	}

	.Language p {
		line-height: 60px;
	}

	.Language span:hover {
		color: #4287e1;
		cursor: pointer;
	}

	.under {
		font-size: 16px;
		font-family: 'Bahnschrift Condensed';
		letter-spacing: -0.5px;
	}

	.under a:hover {
		color: #4287e1;
		cursor: pointer;
	}

	.logoBox {
		position: relative;
		overflow: hidden;
	}

	.logoBox .LogoSpan {
		overflow: hidden;
		left: 0;
		top: 0;
		position: absolute;
	}

	.logoBox .LogoSpanB {
		color: #004aac;
		background-color: transparent;
		height: auto;
		width: auto;
		position: unset;
	}
</style>
