<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerImg="Img1" :one="$i18n.t('titleNine')"></banner2>
		<!-- 面包屑 -->

		<!-- 上部分   -->
		<div class="talk_top">
			<div class="talk_top_cont" style="margin-bottom: 20px;">
				<!--        上 标题-->
				<div class="talk_top_cont_l">
					<h1>{{$i18n.t('contact')}}</h1>
				</div>
				<!--        中 文字-->
				<div class="talk_top_cont_c">
					<div class="talk_top_cont_c_t">
						<div class="talk_top_cont_c_t_l">
							<p>{{$i18n.t('number')}}</p>
							<p>4009613228</p>
						</div>
						<div class="talk_top_cont_c_t_r">
							<p>{{$i18n.t('yx1')}}</p>
							<p>info@eupbusiness.com</p>
						</div>
					</div>
					<div class="talk_top_cont_c_b">
						<div class="talk_top_cont_c_b_r">
							<p>{{$i18n.t('Address_1')}}</p>
							<p>{{$i18n.t('Address_2')}}</p>
						</div>
						<div class="talk_top_cont_c_b_r">
							<p>{{$i18n.t('yx2')}}</p>
							<p>editorial@eupbusiness.com</p>
						</div>
					</div>
				</div>
				<!--        下 公众号-->
				<div style="width: 800px;margin: 0 auto">
					<h3 style="color: #1e1e1e;font-size: 22px;width: 800px;padding-bottom: 25px;">{{$i18n.t('WxGzh')}}
					</h3>
					<div class="Gzh" style="float: left;padding-left: 100px;">
						<p><img src="../assets/images/ylszx.png" height="200" width="200" /></p>
						<p>{{$i18n.t('eup1')}}</p>
					</div>
					<div class="Gzh" style="float: right;padding-right: 100px;">
						<p><img src="../assets/images/ylssb.png" height="200" width="200" /></p>
						<p>{{$i18n.t('eup2')}}</p>
					</div>
				</div>
			</div>
		</div>
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";
	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
		},
		data() {
			return {
				h1: "企业新闻",
				Img1: require("../assets/images/bg.png"),

				p1: "更多最新精彩资讯，感受企业魅力",
			};
		},
		methods: {},
	};
</script>
<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	/*字体 文本居左*/
	html,
	body {
		font-family: "微软雅黑", "宋体", Arail, Tabhoma;
		text-align: left;
	}

	/*去ul li 小点*/
	ul {
		list-style: none;
	}

	/*img IE6 兼容问题*/
	img {
		border: 0 none;
	}

	/*特殊文字粗细 样式显示正常*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}

	/*a标签去下划线*/
	a {
		text-decoration: none;
	}

	.clearfix:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	/*清浮动*/
	a {
		color: #000000;
	}

	/*==========================Reset_End===========================*/

	.youjiaopage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	/* 面包屑 */
	.Crumbs {
		width: 1200px;
		height: 46px;
		margin: 0 auto;
	}

	.Crumbs {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 17px;
	}

	.Crumbs span {
		color: #000000;
	}

	/* 联系我们 */

	.talk_top_cont {
		//display: flex;
		//justify-content: space-between;
		width: 1200px;
		height: 733px;
		background-image: url("../assets/images/6-ContactUs/bg.png");
		background-color: rgba(255, 255, 255);
		margin: 0 auto;
	}

	.talk_top_cont_l {
		margin-left: 200px;
		padding-top: 80px;
		width: 200px;
		height: 80px;
	}

	.talk_top_cont_l h1 {
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #23337f;
		line-height: 45px;
	}

	.talk_top_cont_l p {
		width: 235px;
		height: 80px;
		font-size: 48px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #f6f6f6;
		line-height: 40px;
	}

	.talk_top_cont_c {
		width: 800px;
		height: 200px;
		margin: 0 auto;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 25px;
	}

	.talk_top_cont_r img {
		border: dashed;
	}

	.talk_top_cont_c_t {
		float: left;
		width: 300px;
	}

	.talk_top_cont_c_t_l {
		height: 100px;
		width: 300px;
	}

	.talk_top_cont_c_b {
		float: right;
		width: 500px;
	}

	.talk_top_cont_c_b_r {
		height: 100px;
		width: 500px;
	}

	.Gzh {
		width: 300px;
	}

	.Gzh p {
		text-align: center;
		color: #3f3f3f;
		font-size: 20px;
	}
</style>
