<template>
  <div class="imgAndText" :style="{ width: dWidth + 'px' }">
    <div class="titleSlot">
      <slot name="titleSlot"></slot>
    </div>
    <div class="imgTextBox" :class="disType">
      <!-- 图片 -->
      <div class="imgSlot">
        <slot name="imgSlot"></slot>
      </div>
      <!-- 文本 -->
      <slot name="textSlot"></slot>
    </div>
  </div>
</template>

<script>
/**
 * @Author：yolo
 * @Date: 2024-05-16 19:42
 * @Description 文本和图片的组合模板
 * @dWidth  {Number} 整个模板的宽度,默认1200px
 * @disType {String} 图片与文字的显示关系
 *  flex    图片在左，文字在右水平排列
 *  flexE   图片在右，文字在左水平排列
 *  float   图片在左上角，文字环绕显示
 *  floatR  图片在右上角，文字环绕显示
 * @example 
 * @exampleUrl 
*/
export default {
  name: "imgAndText",
  props: {
    // 模板宽度
    dWidth: {
      type: Number,
      default: 1200,
    },
    disType: {
      type: String,
      default: 'flex',
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() { },
};
</script>

<style scoped>
.imgAndText {}

.imgAndText .titleSlot {
  font-size: 36px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  color: #002459;
}

.imgAndText .imgTextBox {
  display: flex;

}

.imgAndText .imgTextBox.flex {
  display: flex;
}

.imgAndText .imgTextBox.flexE {
  display: flex;
  flex-direction: row-reverse;
}


.imgAndText .imgTextBox.float,.imgAndText .imgTextBox.floatR {
  display: block;
}

.imgAndText .imgTextBox.float>div {
  float: left;
}
.imgAndText .imgTextBox.floatR>div {
  float: right;
}
</style>