<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerImg="Img1" :one="$i18n.t('titleFore') "></banner2>
		<!-- 面包屑 -->
		<!--    <Breadcrumb2 :one="1212"></Breadcrumb2>-->

		<div style="width: 1000px;margin: auto;margin-bottom: 40px">
			<div style="margin-bottom: 40px">
				<h2 style="text-align: center;font-size: 40px;">{{ $i18n.t('titleFore') }}</h2>
			</div>

			<div class="Top"
				style="min-height: 450px; background-color: #f3faff;border: #04497a 3px solid; padding: 20px;font-size: 17px;color: black;">
				<imgAndText :dWidth="950" disType="float">
					<template v-slot:textSlot>
						<!-- 还可以再增加副标题 -->
						<span style="padding-top: 40px;">{{ $i18n.t('OverseasPublishing') }}</span>
					</template>
					<!-- 图片插槽，图片放在这个标签内 -->

					<template v-slot:imgSlot>
						<!-- 图片与文字的间距通过图片的margin控制 -->
						<img src="../assets/img/11111.png" height="300" width="300" style="margin: 0 10px 10px 0;" />
					</template>
				</imgAndText>
			</div>
		</div>


		<!-- 服务流程 -->
		<div class="box">
			<div class=" flow" style="width: auto;">
				<p class="rowTitle" style="margin-bottom: 10px;">{{$i18n.t('serviceProcess')}}</p>
				<!--  column-gap: 点与点之间的间距 160px -->
				<div class="lcArr" style=" column-gap: 100px;">
					<div class="hengLine"></div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
						<div class="lcCircle">01</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('serviceProcess_titleFore_list1')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span
								:style="$i18n.getLocale() == 'en'?'top: -130px;':'top: -45px;'">{{$i18n.t('serviceProcess_titleFore_list2')}}</span>
						</div>
						<div class="lcCircle">02</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
						<div class="lcCircle">03</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('serviceProcess_titleFore_list3')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span
								:style="$i18n.getLocale() == 'en'?'top: -45px;':''">{{$i18n.t('serviceProcess_titleFore_list4')}}</span>
						</div>
						<div class="lcCircle">04</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							<span>沟通客户需求</span> -->
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							<span>沟通客户需求</span> -->
						</div>
						<div class="lcCircle">05</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('serviceProcess_titleFore_list5')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span
								:style="$i18n.getLocale() == 'en'?'top: -30px;':''">{{$i18n.t('serviceProcess_titleFore_list6')}}</span>
						</div>
						<div class="lcCircle">06</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							<span>沟通客户需求</span> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 我们的优势 -->
		<div class="box">
			<div class=" flow">
				<p class="rowTitle" style="margin-bottom: 50px;">{{$i18n.t('ourStrengths')}}</p>
				<div class="ys-box">
					<div class="ys">
						<!-- 01 -->
						<div class="ys-item" style="background-color: whitesmoke;">
							<span class="ys-title">
								01

							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_titleFore_list1')}}</div>
								<div class="yl-tips">{{$i18n.t('ourStrengths_titleFore_list2')}}</div>
							</div>
						</div>
						<!-- 02 -->
						<div class="ys-item">
							<span class="ys-title">
								02

							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_titleFore_list3')}}</div>
								<div class="yl-tips">{{$i18n.t('ourStrengths_titleFore_list4')}}</div>
							</div>
						</div>
						<!-- 0304 -->
						<div class="ys-item ys-item-col2">
							<img data-v-72e92cc0=""
								src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt="" srcset=""
								style="width: 100%;height: 100%;">
						</div>
						<!-- 0506 -->
						<div class="ys-item ys-item-col2">
							<img data-v-72e92cc0=""
								src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt="" srcset=""
								style="width: 100%;height: 100%;">
						</div>
						<!-- 03 -->
						<div class="ys-item ys-item-dark">
							<span class="ys-title">
								03

							</span>
							<div class="yl-body">
								<div class="yl-content" style="">{{$i18n.t('ourStrengths_titleFore_list5')}}</div>
								<div class="yl-tips">{{$i18n.t('ourStrengths_titleFore_list6')}}</div>
							</div>
						</div>
						<!-- 04 -->
						<div class="ys-item">
							<span class="ys-title">
								04

							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_titleFore_list7')}}</div>
								<div class="yl-tips">{{$i18n.t('ourStrengths_titleFore_list8')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>



		<!-- 品牌案例 -->
		<div class="box">
			<div class="flow" style="margin-bottom: 40px;">
				<p style="margin-bottom: 35px; margin-top: 40px;">合作媒体 </p>
				<!-- <div class="hx-box">
					<div class="hx"></div>
				</div> -->
				<div class="al-box">
					<img src="../assets/img/titleFive/brand.png" style="width: 860px;height: 446px;" alt="" srcset="">
				</div>
			</div>
		</div>


		<footer2></footer2>
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";
	// 引入组件
	import imgAndText from "../components/models/imgAndText.vue";
	// import Breadcrumb2 from "../components/Breadcrumb.vue";

	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
			imgAndText
			// Breadcrumb2,
		},
		data() {
			return {
				h1: "集团新闻",
				p1: "更多最新精彩资讯，感受企业魅力",
				active: "GroupNews",
				newsList: [{
						title: "哈哈哈和",
						url: "GroupNews",
					},
					{
						title: "媒体报道",
						url: "MediaCoverage",
					},
					{
						title: "行业资讯",
						url: "TradeNews",
					},
				],
				Img1: require("../assets/images/bg.png"),
			};
		},

		// watch: {
		//   $route(now, old) {
		//     // now: 变化之后的数据
		//     // old: 变化之前的数据
		//     let url = now.name;
		//     console.log(url);
		//     console.log(now, old);
		//     if (url.includes("TradeNews")) {
		//       this.active == "TradeNews";
		//     }
		//   },
		//   immediate: true,
		// },
		watch: {
			$route: {
				// 值发生变化时的回调函数
				handler(now) {
					//..... // now: 变化之后的数据
					// old: 变化之前的数据
					let url = now.name;

					// console.log(url);
					// console.log(now, old);
					if (url.includes("TradeNews")) {
						this.active = "TradeNews";
					} else if (url.includes("GroupNews")) {
						this.active = "GroupNews";
					} else {
						this.active = "MediaCoverage";
					}
				},
				immediate: true, //立即执行
			},
		},
		methods: {
			NewsDetails(url) {
				this.$router.push({
					name: url,
				});
				// this.active = url;
			},
		},
	};
</script>
<style>
	@charset "utf-8";
	
	/*=========================Reset_start==========================*/
	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	div,
	p,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	form,
	table,
	th,
	td,
	a,
	img,
	span,
	strong,
	var,
	em,
	input,
	textarea,
	select,
	option {
		margin: 0;
		padding: 0;
	}
	
	/*去内外边距*/
	html,
	body {
		font-family: "微软雅黑", "宋体", Arail, Tabhoma;
		text-align: left;
	}
	
	/*字体 文本居左*/
	ul {
		list-style: none;
	}
	
	/*去ul li 小点*/
	img {
		border: 0 none;
	}
	
	/*img IE6 兼容问题*/
	input,
	select,
	textarea {
		outline: 0;
	}
	
	/*去除点击蓝框*/
	textarea {
		resize: none;
		overflow: auto;
	}
	
	/*锁定多行文本框大小 与文字超出出现滚动条*/
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	/*表格边框合并 边框间距0*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}
	
	/*特殊文字粗细 样式显示正常*/
	a {
		text-decoration: none;
	}
	
	
	* {
		margin: 0;
		padding: 0;
	}

	/* .titleSlot {
		padding: 0;
	}

	.Top {}

	.subTitle {
		font-weight: 600;
		font-size: 25px;
		line-height: 30px;
		display: block;
		margin: 5px 0px 10px;
	} */
</style>
