<template>
<div>
  <nav2></nav2>
  <banner2 :bannerImg="Img1" :one="$i18n.t('bc6_t')"></banner2>
  <div class="fixed-width-container container row" style="margin-top: 50px;margin-bottom: 30px;">
    <div class="col-12">
      <h1 style="text-align: center;" class="display-6">{{$i18n.t('bc6_t')}}</h1>
    </div>
    <div style="margin-top: 30px;" class="row">
      <div class="h3 col-lg-3">{{$i18n.t('AnLi6_h3_1')}}</div>
      <div class="col-lg-9">{{$i18n.t('AnLi6_h3_1_1')}}</div>
    </div>
    <div style="margin-top: 35px" class="row">
      <div class="col-12 h3" style="text-align: center;margin-bottom: 20px;">{{$i18n.t('AnLi6_h3_2')}}</div>
      <div>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_2_1_1')}}</span>{{$i18n.t('AnLi6_h3_2_1_2')}}</p>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_2_2_1')}}</span>{{$i18n.t('AnLi6_h3_2_2_2')}}</p>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_2_3_1')}}</span>{{$i18n.t('AnLi6_h3_2_3_2')}}</p>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_2_4_1')}}</span>{{$i18n.t('AnLi6_h3_2_4_2')}}</p>
      </div>
      <div>
        <p style="text-align: center">
          <img src="../../assets/images/AnLi/6-2.jpg" height="210" width="400"/>
          <img src="../../assets/images/AnLi/6-1.jpg" height="210" width="400"/>
        </p>
      </div>
    </div>
    <div style="margin-top: 35px" class="row">
      <div class="col-12 h3" style="text-align: center;margin-bottom: 20px;">{{$i18n.t('AnLi6_h3_3')}}</div>
      <div>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_3_1_1')}}</span>{{$i18n.t('AnLi6_h3_3_1_2')}}</p>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_3_2_1')}}</span>{{$i18n.t('AnLi6_h3_3_2_2')}}</p>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_3_3_1')}}</span>{{$i18n.t('AnLi6_h3_3_3_2')}}</p>
        <p><span style="font-weight: 600;">{{$i18n.t('AnLi6_h3_3_4_1')}}</span>{{$i18n.t('AnLi6_h3_3_4_2')}}</p>
      </div>
    </div>
    <div style="margin-top: 30px;" class="row">
      <div class="h3 col-lg-3">{{$i18n.t('AnLi6_h3_4')}}</div>
      <div class="col-lg-9">{{$i18n.t('AnLi6_h3_4_1')}}</div>
    </div>
  </div>
  <footer2></footer2>
</div>
</template>

<script setup>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";

export default {
  name: "index",
  components: {
    footer2,
    nav2,
    banner2,
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
  },
  data() {
    return {
      Img1: require("../../assets/img/keji/矿山banner1.png"),
    };
  },
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
.fixed-width-container {
  width: 800px !important; /* 设置固定宽度 */
  max-width: 100%; /* 确保在小屏幕上宽度占满容器 */
  margin: 0 auto; /* 水平居中 */
}
</style>
