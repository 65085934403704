<template>
  <div>
    <!-- ---------- -->
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/img/keji/矿山banner1.png" class="d-block w-100" alt="...">
          <div class="carousel-caption">
            <h5 class="display-5">{{ bannerH }}{{ bannerP }}</h5>
          </div>
        </div>
      </div>
    </div>
    <!-- <nav class=" fixed-width-container container" style="--bs-breadcrumb-divider:'';" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item " style="font-size: 14px;">&nbsp;&nbsp;{{$i18n.t('currentLocation')}} <a>{{ one }}</a></li>
        <li class="breadcrumb-item active" style="font-size: 14px;" aria-current="page">{{ two }}</li>
      </ol>
    </nav> -->
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {};
  },
  props: ["bannerH", "bannerP", "bannerImg","one","two"],
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
.carousel-caption {
  top: 50%; /* 根据需要调整 */
  bottom: auto;
  transform: translateY(-50%);
}
.fixed-width-container {
  width: 1200px !important; /* 设置固定宽度 */
  max-width: 100%; /* 确保在小屏幕上宽度占满容器 */
  margin: 0 auto; /* 水平居中 */
}
</style>
