<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<banner2  :bannerImg="Img1" :one="$i18n.t('titleTwo')"></banner2>
		<!-- 关于企业 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<!-- <div class="about-top-l">
            <span> {{$i18n.t('SyWepWire')}} </span>
            <p :style="{lineHeight: ($i18n.getLocale() === 'en' ? '20px' : '40px')}" style="font-size: 18px;margin-top: 20px">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$i18n.t('WepWire')}}
            </p>
          </div>
          <div class="about-top-r">
            <div class="img-yinying"></div>
            <img src="../assets/images/1-AboutUs/1-1.png"/>
          </div> -->
					<imgAndText :dWidth="1250" disType="flexE">
						<template v-slot:textSlot>
							<div class="about-top-l">
								<span> {{ $i18n.t('SyWepWire') }} </span>
								<p :style="{ lineHeight: ($i18n.getLocale() === 'en' ? '20px' : '40px') }"
									style="font-size: 18px;margin-top: 20px">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $i18n.t('WepWire') }}
								</p>
							</div>
						</template>

						<template v-slot:imgSlot>
							<img style="width: 500px;" src="../assets/images/0-EupWire/1-1.png" />
						</template>
					</imgAndText>
				</div>
				<div class="about-down">
					<div class="item">
						<p style="text-align: center">
							<span class="item-1">150</span>
							<span class="item-2">+</span>
						</p>
						<p class="item-3">{{ $i18n.t('medium') }}</p>
					</div>
					<div class="item">
						<p style="text-align: center">
							<span class="item-1">200</span>
							<span class="item-2">+</span>
						</p>
						<p class="item-3">{{ $i18n.t('yy') }}</p>
					</div>
					<div class="item">
						<p style="text-align: center">
							<span class="item-1">200</span>
							<span class="item-2">+</span>
						</p>
						<p class="item-3">{{ $i18n.t('hyzj') }}</p>
					</div>
					<div class="item">
						<p style="text-align: center">
							<span class="item-1">6W</span>
							<span class="item-2">+</span>
						</p>
						<p class="item-3">{{ $i18n.t('yzmt') }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 企业文化 -->
		<div class="qywh" id="CultureInfo">
			<div style="width: 1200px; margin-left: auto; margin-right: auto;">
				<h1>{{ $i18n.t('qywh') }}</h1>
				<div class="qywh-item">
					<div class="qywh-item1" style="margin-right: 200px;">
						<p><img src="../assets/img/编组 5.png" /></p>
						<p class="qywh-text">{{ $i18n.t('qyln') }}</p>
						<p class="qywh-text2" v-if="$i18n.getLocale() == 'zh'" style="text-align: center;">
							促进社会繁荣<br/>增进人民幸福
						</p>
						
						<p class="qywh-text2" v-else style="text-align: center;">
							Promote social prosperity <br/> Enhance people's happiness
						</p>
					</div>
					<div class="qywh-item1">
						<p><img src="../assets/img/编组 10.png" /></p>
						<p class="qywh-text">{{ $i18n.t('qyyj') }}</p>
						<p class="qywh-text2" v-if="$i18n.getLocale() == 'zh'" style="padding: 0 30px;">
							助力人才多元化<br/>助力商业全球化
						</p>
						<p class="qywh-text2" v-else style="padding: 0 30px;">
							Assist in talent diversification <br/> Facilitate business globalization
						</p>
					</div>
					<div class="qywh-item1" style="margin-left: 200px;">
						<p><img src="../assets/img/编组 14.png" alt="" /></p>
						<p class="qywh-text">{{ $i18n.t('qykh') }}</p>
						<p class="qywh-text2" v-if="$i18n.getLocale() == 'zh'"  :style="$i18n.getLocale() == 'zh'?'':'line-height: 24px;'" >
							传播中国声音<br/>链接中外商业<br/>沟通记录时代
						</p>
						
						<p class="qywh-text2" v-else  :style="$i18n.getLocale() == 'zh'?'':'line-height: 24px;'" >
							Amplify the voice of China<br/> Foster connections between domestic and foreign businesses <br/> Communicate the trends and events of our time
						</p>
					</div>
				</div>
			</div>

		</div>
		<!-- 企业历程 -->
		<div style="width: 100%;background-color: #f7f7f7;padding-bottom: 40px;">
			<h1 style="text-align: center;margin-bottom: 40px;color: #444444">{{ $i18n.t('EnterpriseDevelopment') }}
			</h1>
			<div class="under">
				<div>
					<h4>2019.11</h4>
					<p>{{ $i18n.t('Rc_1') }}</p>
				</div>
				<div>
					<h4>2020.03</h4>
					<p>{{ $i18n.t('Rc_2') }}</p>
				</div>
				<div>
					<h4>2022.11</h4>
					<p>{{ $i18n.t('Rc_3') }}</p>
				</div>
				<div>
					<h4>2022.12</h4>
					<p>{{ $i18n.t('Rc_4') }}</p>
				</div>
				<div>
					<h4>2022.12</h4>
					<p>{{ $i18n.t('Rc_5') }}</p>
				</div>
				<div>
					<h4>2023.05</h4>
					<p>{{ $i18n.t('Rc_6') }}</p>
				</div>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
// 引入组件
import imgAndText from "../components/models/imgAndText.vue";
// import $ from  "jquery"
export default {
	name: "App",
	data() {
		return {
			Img1: require("../assets/images/1-AboutUs/1-1-bg.png"),
		};
	},
	components: {
		daohang,
		footer2,
		banner2,
		imgAndText
	},
	created() {
		console.log('this.$i18n.t： ' + this.$i18n.t)
		console.log('this.$i18n.t.locale： ' + this.$i18n.getLocale())
		console.log()
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		// if (this.$i18n.t.locale === "en") {
		//   $(".about-top-l > p").css("line-height","20px")
		// } else {
		//   $(".about-top-l > p").css("line-height","40px")
		// }
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
	},
};
</script>

<style scoped>
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}

.about {
	height: 700px;
	position: relative;
	margin: 100px 200px;
	display: flex;
	justify-content: center;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

.about-top-l {
	position: absolute;
	left: 17px;
	top: 40px;
	width: 611px;
	height: 442px;
}

.about-top-l>span {
	font-size: 32px;
	color: #666666;
}

.about-top-l>p {
	font-size: 16px;
	text-align: justify;
	text-justify: inter-ideograph;
	color: #999;
	font-variant-ligatures: common-ligatures;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

	/* //line-height: 20px; */
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

.about-top-r {
	position: absolute;
	right: -110px;
}

.about-top-r img {
	width: 500px;
	height: 500px;
}

.about-down {
	width: 1200px;
	position: absolute;
	bottom: 20px;
	padding-left: 20px;
}

.item {
	top: 236px;
	float: left;
	width: 290px;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	color: rgba(35, 51, 127, 1);
	font-size: 20px;
}

.item-3 {
	color: rgba(102, 102, 102, 1);
	font-size: 14px;
	text-align: center;
}

.qywh {
	width: 100%;
	height: 598px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	text-align: center;
}

.qywh-item {

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	position: relative;
	width: 400px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 p {
	margin-top: 20px;
	text-align: center;
}

.qywh-item1 span>img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	margin-top: 15px;
	color: rgba(68, 68, 68, 1);
	font-size: 20px;
}

.qywh-text2 {
	padding: 0px 20px;
	font-size: 16px;
	line-height: 30px;
}

.under {
	width: 1200px;
	margin: 0 auto;
	padding-bottom: 20px;
	background-color: #ffffff;
	box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.1);
}

.under div {
	width: 1200px;
}

.under h4 {
	padding-top: 20px;
	text-align: left;
	margin-left: 20px;
	margin-bottom: 5px;
}

.under p {
	padding-left: 20px;
	padding-right: 20px;
}
</style>
