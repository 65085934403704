<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2  :bannerImg="Img1" :one="$i18n.t('titleSeven')"></banner2>

		<div v-if="$i18n.getLocale() == 'zh'" class="images" style="">
			<div>
				<img src="../assets/SentimentMonitoring/cn/0.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/1.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/2.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/3.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/4.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/5.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/3.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/7.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/8.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/cn/9.jpg" alt="" />
			</div>
		</div>
		<div  v-if="$i18n.getLocale() == 'en'" class="images" style="">
			<div>
				<img src="../assets/SentimentMonitoring/en/0.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/1.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/2.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/3.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/4.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/5.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/3.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/7.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/8.jpg" alt="" />
			</div>
			<div>
				<img src="../assets/SentimentMonitoring/en/9.jpg" alt="" />
			</div>
		</div>

		<footer2></footer2>
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";
	// 引入组件
	// import flow from "../components/models/flow.vue";

	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
			// flow
		},
		data() {
			return {
				h1: "企业新闻",
				Img1: require("../assets/img/keji/矿山banner1.png"),
				p1: "更多最新精彩资讯，感受企业魅力",
			};
		},
		methods: {},
	};
</script>

<style scoped>
	@charset "utf-8";

	/*=========================Reset_start==========================*/
	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	div,
	p,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	form,
	table,
	th,
	td,
	a,
	img,
	span,
	strong,
	var,
	em,
	input,
	textarea,
	select,
	option {
		margin: 0;
		padding: 0;
	}

	/*去内外边距*/
	html,
	body {
		font-family: "微软雅黑", "宋体", Arail, Tabhoma;
		text-align: left;
	}

	/*字体 文本居左*/
	ul {
		list-style: none;
	}

	/*去ul li 小点*/
	img {
		border: 0 none;
	}

	/*img IE6 兼容问题*/
	input,
	select,
	textarea {
		outline: 0;
	}

	/*去除点击蓝框*/
	textarea {
		resize: none;
		overflow: auto;
	}

	/*锁定多行文本框大小 与文字超出出现滚动条*/
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/*表格边框合并 边框间距0*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}

	/*特殊文字粗细 样式显示正常*/
	a {
		text-decoration: none;
	}


	.images {
		text-align: center;
	}

	.images>div {
		margin-top: 30px;
	}

	.images>div>img {
		width: 1200px;
	}


	/*a标签去下划线*/
	.clearfix:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	/*清浮动*/
	a {
		color: #000000;
	}

	/*==========================Reset_End===========================*/

	.youjiaopage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	/* 面包屑 */
	.Crumbs {
		width: 1500px;
		height: 46px;
		margin: 0 auto;
	}

	.Crumbs {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 17px;
	}

	.Crumbs div {
		line-height: 46px;
	}

	.Crumbs span {
		color: #000000;
	}

	/* 新闻详情 */
	.NewsDetails_cont {
		width: 1500px;
		margin: 0 auto;
	}

	.NewsDetails_cont_title>h1 {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2b2b2b;
		line-height: 33px;
		margin-top: 78px;
		text-align: center;
	}

	.NewsDetails_cont_title img {
		vertical-align: middle;
	}

	.NewsDetails_cont_title_span {
		text-align: center;

		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #a3a3a3;
		line-height: 30px;
		margin-top: 8px;
	}

	.NewsDetails_cont_text {
		margin-top: 40px;
		border-top: 1px solid #eaeaea;
	}

	.NewsDetails_cont_text p {
		line-height: 30px;
		width: 1000px;
		margin: 40px 100px;
	}

	.NewsDetails_cont_text div {
		margin-left: 90px;
		font-size: 24px;
		font-weight: bold;
	}

	.NewsDetails_cont_img {
		text-align: center;
		margin-bottom: 40px;
	}

	.NewsDetails_cont_footer {
		padding: 0 40px;

		line-height: 68px;
		width: 1120px;
		height: 68px;
		background: #f8f8f8;
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm div {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		line-height: 33px;
	}

	.NewsDetails_cont_btm p {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
	}

	.NewsDetails_cont_btm2 {
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm2_1 {
		margin: 32px 0;
		padding: 31px 61px 31px 20px;
		width: 507px;
		height: 123px;
		background: #ffffff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
	}

	.NewsDetails_cont_btm2_1 div {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 25px;
	}

	.NewsDetails_cont_btm2_1 p {
		margin-top: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 28px;
	}

	.box {
		display: flex;
		justify-content: center;

		padding-bottom: 40px;
	}

	.main {
		width: 1250px;
		padding-top: 60px;
	}

	.display-5 {
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
		color: #002459;
	}

	.info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 50px;
	}

	.info-text {
		padding-left: 53.2px;
		box-sizing: border-box;
		color: #666666;
		font-size: 16px;
		line-height: 27px;
	}

	.info-text p {
		font-size: 16px;
		line-height: 24px;
		color: #666666;
		margin-top: 30px;
	}

	.fwtitle {
		font-size: 30px;
		line-height: 60px;
		color: #333333;
		font-weight: bold;
		margin-top: -10px;
	}

	/* models */
	.titleSlot {
		padding: 20px 0 40px;
	}

	.textTitle {
		box-sizing: border-box;
		color: #666666;
		font-size: 16px;
		line-height: 27px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.textTitle.textTitleCenter {
		justify-content: center;
	}

	.textTitle.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	.textTitle .textContent {
		text-indent: 2em;
	}

	.subTextTitle {
		font-size: 20px;
		font-weight: 600;
		color: #333;
		margin: 15px 0 5px;
		text-align: center;
	}

	.box2 {
		column-gap: 100px;
	}

	.boxLeft {
		padding: 15px;
		border: 2px solid;
		border-image: linear-gradient(to right, #7094d3, #7094d36b) 1;
	}

	.boxRight {
		padding: 15px;
		border: 2px solid;
		border-image: linear-gradient(to right, #7094d36b, #7094d3) 1;
	}

	.flow {
		width: 100%;
		background: #fff;
		padding: 20px 0;
	}

	.flow p {
		text-align: center;
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
		color: #002459;
		margin-bottom: 20px;
	}

	.flow .hx-box {
		display: flex;
		justify-content: center;
	}

	.flow .hx {
		border-top: 2px solid #333;
		width: 60px;
		text-align: center;
	}

	.bs {
		background: #fff;
	}

	.ys-box {
		display: flex;
		justify-content: center;
	}

	.ys {
		display: flex;
	}

	.ys img:hover {
		opacity: 0.8;
	}

	.al-box {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.al {
		display: flex;
		width: 1500px;
		justify-content: center;
	}

	.al img {
		width: 25%;
	}
</style>
