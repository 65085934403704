<template>
	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<!-- 红人 -->
		<div class="header" @mousemove="przMove">
			<div class="headerModel">
				<imgAndText :dWidth="1200" disType="flex">
					<template v-slot:textSlot>
						<div class="titleBox">
							<div class="title">{{$i18n.t('tf2_title_text1')}}</div>
							<!-- 还可以再增加副标题 -->
							<div class="content">
								{{$i18n.t('tf2_title_text2')}}
							</div>
						</div>
					</template>
					<!-- 图片插槽，图片放在这个标签内 -->

					<template v-slot:imgSlot>
						<!-- 图片与文字的间距通过图片的margin控制 -->
						<img :style="transformStyle" src="../../assets/img/titleFive/hongr.png"
							style="margin: 0 10px 10px 0;" />
					</template>
				</imgAndText>
			</div>

		</div>
		<!-- 数字展示 -->
		<div class="numBox ">
			<div class="w1200 ">
				<div class="numItem " style="visibility: visible; animation-duration: 2s; animation-name: fadeInDown;">
					<div class="fontTitle ">
						80000+
					</div>
					<div class="fontContent">
						<div class="numBox-of fnt_20 ">{{$i18n.t('tf2_b_t1')}}</div>
						<div class="numBox-exp">{{$i18n.t('tf2_b_text1')}}</div>
					</div>
				</div>
				<div class="nuline"></div>
				<div class=" numItem " style="visibility: visible; animation-duration: 2s; animation-name: fadeInDown;">
					<div class="">
						<div class="fontTitle ">60+
						</div>
						<div class="fontContent">
							<div class="numBox-of fnt_20">{{$i18n.t('tf2_b_t2')}}</div>
							<div class="numBox-exp">{{$i18n.t('tf2_b_text2')}}</div>
						</div>
					</div>
				</div>
				<div class="nuline"></div>
				<div class=" numItem  "
					style="visibility: visible; animation-duration: 2s; animation-name: fadeInDown;">
					<div class="fontContent">
						<div class="fontTitle ">200+
						</div>
						<div class="">
							<div class="numBox-of fnt_20">{{$i18n.t('tf2_b_t3')}}</div>
							<div class="numBox-exp">{{$i18n.t('tf2_b_text3')}}</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- 线下活动参与 -->
		<div class="activity" ref="activityRef">
			<div class="w1200">
				<div class="acTitle wow-ac fadeInDown" data-wow-duration="5s">{{$i18n.t('tf2_hz_title_text1')}}
				</div>
				<div class="acline wow-ac fadeInDown" data-wow-duration="1s"></div>
				<div class="acContetn wow-ac fadeInDown" data-wow-duration="1.5s">
					{{$i18n.t('tf2_hz_title_text2')}}
				</div>
				<div class="acBody">
					<div class="hr_img0 wow-ac-2"><img src="../../assets/img/titleFive/hr_img0.png" /></div>
					<div class="hr_img1 wow-ac-2">
						<div class="acText hr_img1_text">
							<div class="acTextTitle">{{$i18n.t('tf2_hz_text1')}}</div>
							<div class="acTextContent"></div>
						</div>
						<img src="../../assets/img/titleFive/hr_img1.png" />
					</div>
					<div class="hr_img2 wow-ac-2">
						<div class="acText2 hr_img1_text">
							<div class="acTextTitle">{{$i18n.t('tf2_hz_text3')}}</div>
							<div class="acTextContent"></div>
						</div>
						<img src="../../assets/img/titleFive/hr_img2.png" />
					</div>
					<div class="hr_img3 wow-ac-2">
						<div class="acText3 hr_img1_text">
							<div class="acTextTitle">{{$i18n.t('tf2_hz_text2')}}</div>
							<div class="acTextContent"></div>
						</div>
						<img src="../../assets/img/titleFive/hr_img3.png" />
					</div>
					<div class="hr_img4 wow-ac-2">
						<div class="acText4 hr_img1_text">
							<div class="acTextTitle">{{$i18n.t('tf2_hz_text4')}}</div>
							<div class="acTextContent"></div>
						</div>
						<img src="../../assets/img/titleFive/hr_img4.png" />
					</div>
				</div>
			</div>
		</div>
		<!-- 服务流程 -->
		<div class="box">
			<div class=" flow" style="width: auto;">
				<p class="rowTitle" :style="$i18n.getLocale() == 'en'?'margin-bottom: 20px;':'margin-bottom: 10px;'" >{{$i18n.t('serviceProcess')}}</p>
				<!--  column-gap: 点与点之间的间距 160px -->
				<div class="lcArr" style=" column-gap: 100px;">
					<div class="hengLine"></div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
						<div class="lcCircle">01</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('tf2_service_text1')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span :style="$i18n.getLocale() == 'en'?'top: -140px;':'top: -45px;'">{{$i18n.t('tf2_service_text2')}}</span>
						</div>
						<div class="lcCircle">02</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
						<div class="lcCircle">03</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('tf2_service_text3')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span :style="$i18n.getLocale() == 'en'?'top: -60px;':'top: -45px;'" >{{$i18n.t('tf2_service_text4')}}</span>
						</div>
						<div class="lcCircle">04</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							<span>沟通客户需求</span> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 我们的优势 -->
		<div class="box">
			<div class=" flow" :style="$i18n.getLocale() == 'en'?'margin-top: 20px;':'margin-top: 0px;'">
				<p class="rowTitle" style="margin-bottom: 50px;">{{$i18n.t('ourStrengths')}}</p>
				<div class="ys-box">
					<div class="ys">
						<!-- 01 -->
						<div class="ys-item" style="background-color: whitesmoke;">
							<span class="ys-title">
								01
		
							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_list1')}} </div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
						<!-- 02 -->
						<div class="ys-item" style="background-color: #fff;">
							<span class="ys-title">
								02
		
							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_list2')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
						<!-- 0304 -->
						<div class="ys-item ys-item-col2">
							<img data-v-72e92cc0=""
								src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt="" srcset=""
								style="width: 100%;height: 100%;">
						</div>
						<!-- 0506 -->
						<div class="ys-item ys-item-col2">
							<img data-v-72e92cc0=""
								src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt="" srcset=""
								style="width: 100%;height: 100%;">
						</div>
						<!-- 03 -->
						<div class="ys-item ys-item-dark">
							<span class="ys-title">
								03
		
							</span>
							<div class="yl-body">
								<div class="yl-content" style="">{{$i18n.t('ourStrengths_tf3_list1')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
						<!-- 04 -->
						<div class="ys-item" style="background-color: #fff;">
							<span class="ys-title">
								04
		
							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_tf3_list2')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import nav2 from "../../components/nav.vue";
import imgAndText from "../../components/models/imgAndText.vue";
import $ from "jquery";
function activityAddClass() {
	console.log('activityAddClass:');
	$('.activity').each(function () {
		$(this).find('.fadeInDown').addClass('move');
	})
}
function serviceAddClass() {
	console.log('serviceAddClass:');
	$('.ser-moveBox').each(function () {
		$(this).find('.ser-step').addClass('move');
		$(this).find('.step-txt').addClass('move');
		$(this).find('.ser-curve').addClass('move');
	})
	$('.tech-img').each(function () {
		$(this).addClass('move');
	})
	$('.us-cloud-box div').each(function () {
		$(this).addClass('move')
	})
}
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		imgAndText,
	},
	data() {
		return {
			loadStatus: {
				activity: false,
				serve: false,
			}
		};
	},
	methods: {
		// 颤动函数， 把transformStyle给需要动的对象
		przMove(e) {
			var x = e.pageX / 1920 - 0.5;
			var y = (e.pageY - 700) / 700 - 0.5;
			var intensity = 20;
			this.transformStyle = {
				'transform': 'translate3d(' + -x * intensity + 'px, ' + -y * intensity + 'px, 0)'
			}
		},
		handleScroll() {
			console.log('window.scrollY:', window.scrollY);
			if (window.scrollY > 600 && !this.loadStatus.activity) {
				this.loadStatus.activity = true;
				activityAddClass()
			}
			if (window.scrollY > 1000 && !this.loadStatus.serve) {
				this.loadStatus.serve = true;
				serviceAddClass()
			}
		}
	},
	mounted() {
		// this.$nextTick(() => {
		// 	addClass()
		// })
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		// 组件销毁前移除事件监听
		window.removeEventListener('scroll', this.handleScroll);
	}
};
</script>

<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}

/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}

/*字体 文本居左*/
ul {
	list-style: none;
}

/*去ul li 小点*/
img {
	border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}

/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}

/*清浮动*/
a {
	color: #000000;
}

/*==========================Reset_End===========================*/
/* 公共 */


/* 头部 */
.youjiaopage_wrap {
	min-height: 100%;
}

.header {
	width: 100%;
	height: 600px;
	/* background: url(../../assets/img/titleFive/hr_bg10.jpg) no-repeat center; */
	background-color: #FFF;
	background-size: cover;

}

.headerModel {
	width: 100%;
	height: 100%;
	background-color: #FFF;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.titleBox {
	
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.title{
	color:#004aac;
}
.content {
	color: #000;
}

.title {
	font-size: 28px;
	margin-bottom: 20px;
	font-weight: 600;
}

.content {
	line-height: 29px;
}

/* 数字展示 */

.numBox {
	width: 100%;
	padding: 80px 0 100px 0;
	background: #004aac;
}

.nuline {
	width: 1px;
	height: 100px;
	background-color: #fff;
}

.numItem {
	width: 400px;
	height: 180px;
	/* background: url(../../assets/img/titleFive/ly_img09.jpg) no-repeat right 80px; */
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.numBox .fontTitle {
	font-family: 'Arial';
	font-weight: bold;
	color: #FFF;
	text-align: center;
	font-size: 75px;
}

.numBox .w1200 {
	display: flex;
	width: 1200px;
	margin: 0 auto;
}

.fontContent>div {
	text-align: center;
}

.numBox .numBox-of {
	color: #FFF;
	margin-top: 10px;
	text-align: center
}

.numBox-exp {
	color: #FFF;
	font-size: 14px;
	margin-top: 5px;
	text-align: left
}

.numBox-exp span {
	font-family: 'Arial';
}

.fontTitle {
	font-family: 'Arial';
	font-weight: bold;
	color: #FFF;
	text-align: left;
}

/* 线下活动参与 */
.activity {
	width: 100%;
	background-color: #fff;
}

.activity .w1200 {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 55px 0 0px;
	display: flex;
	width: 1200px;
	margin: 0 auto;
}

.acTitle {
	font-size: 30px;
	color: #333;
	font-weight: 500;
}

.acline {
	width: 120px;
	height: 2px;
	background-color: #444;
	margin: 20px 0 15px;
}

.acContetn {
	color: #7d7d7d;
	line-height: 30px;
	width: 355px;
	text-align: center;
}

.acBody {
	width: 1000px;
	position: relative;
	height: 370px
}

.acBody>div {
	position: absolute;
}

.acBody .hr_img0 {
	width: 260px;
	left: calc(50% - 130px);
	bottom: 0;
}

.acBody .hr_img1 {
	top: 60px;
	left: 110px;
}

.acBody .hr_img2 {
	bottom: 90px;
	left: 90px;
}

.acBody .hr_img3 {
	top: 85px;
	right: 230px;
}

.acBody .hr_img4 {
	bottom: 85px;
	right: 170px;
}

.acBody .acText {
	position: absolute;
	bottom: 100%;
}

.acBody .acText2 {
	position: absolute;
	bottom: 0;
}

.acBody .acText3 {
	position: absolute;
	bottom: 100%;
	right: 0;
}

.acBody .acText4 {
	position: absolute;
	bottom: 0;
	right: 0;
}

.acBody .acTextTitle {
	font-size: 16px;
	margin: 3px 0;
	color: rgba(35, 51, 127, 1);
	font-weight: 500;
}

.acBody .acTextContent {
	font-size: 14px;
	color: #a9a9a9;
	line-height: 20px;
	margin-bottom: 5px;
}

/* 路线图 */

.us-service {
	width: 100%;
	height: 810px;
	background: #fafbff;
	overflow: hidden;
}

.us-service .w1200 {
	height: 100%;
	margin: 0 auto;
}

.us-service .head-box {
	margin-top: 55px;
}

.us-service .head-cn {
	color: #000;
	text-align: center;
	font-size: 24px;
}

.us-service .head-en {
	color: #7d7d7d;
	text-align: center
}

.us-service .bd-line {
	width: 99px;
	height: 50px;
	background: url(../../assets/img/titleFive/ly_img06.png) no-repeat center;
	margin: 0 auto;
}

.us-service .head-txt {
	color: #7d7d7d;
	line-height: 30px;
	text-align: center
}

.us-service .ser-proc {
	width: 830px;
	height: 330px;
	margin: 140px auto 0 auto;
	position: relative;
}

.us-service .ser-proc .ser-step {
	width: 43px;
	height: 43px;
	background: url(../../assets/img/titleFive/ly_serpoint.png) no-repeat center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	opacity: 0;
}

.us-service .ser-proc .ser-step.move {
	opacity: 1
}

.pra {
	position: absolute;
}

.us-service .ser-proc .ser-step1 {
	left: 0;
	top: 0;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt1 {
	left: -78px;
	top: -60px;
	-webkit-transition: all 0.5s 0.4s;
	-moz-transition: all 0.5s 0.4s;
	-o-transition: all 0.5s 0.4s;
	-ms-transition: all 0.5s 0.4s;
	transition: all 0.5s 0.4s;
}

.us-service .ser-proc .ser-curve1 {
	width: 0;
	height: 94px;
	background: url(../../assets/img/titleFive/ly_ser01.png) no-repeat left top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	left: 43px;
	top: 22px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.us-service .ser-proc .ser-curve1.move {
	width: 94px;
}

.us-service .ser-proc .ser-step2 {
	left: 114px;
	top: 114px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt2 {
	left: -58px;
	top: 110px;
	-webkit-transition: all 0.5s 0.8s;
	-moz-transition: all 0.5s 0.8s;
	-o-transition: all 0.5s 0.8s;
	-ms-transition: all 0.5s 0.8s;
	transition: all 0.5s 0.8s
}

.us-service .ser-proc .ser-curve2 {
	width: 0;
	height: 94px;
	background: url(../../assets/img/titleFive/ly_ser02.png) no-repeat left top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	left: 133px;
	top: 155px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.us-service .ser-proc .ser-curve2.move {
	width: 94px;
}

.us-service .ser-proc .ser-step3 {
	left: 223px;
	top: 224px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt3 {
	left: 216px;
	top: 286px;
	-webkit-transition: all 0.5s 1.2s;
	-moz-transition: all 0.5s 1.2s;
	-o-transition: all 0.5s 1.2s;
	-ms-transition: all 0.5s 1.2s;
	transition: all 0.5s 1.2s;
}

.us-service .ser-proc .ser-curve3 {
	width: 0;
	height: 94px;
	background: url(../../assets/img/titleFive/ly_ser03.png) no-repeat left top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	left: 261px;
	top: 155px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.us-service .ser-proc .ser-curve3.move {
	width: 94px;
}

.us-service .ser-proc .ser-step4 {
	left: 334px;
	top: 114px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt4 {
	left: 382px;
	top: 170px;
	-webkit-transition: all 0.5s 1.6s;
	-moz-transition: all 0.5s 1.6s;
	-o-transition: all 0.5s 1.6s;
	-ms-transition: all 0.5s 1.6s;
	transition: all 0.5s 1.6s;
}

.us-service .ser-proc .ser-curve4 {
	width: 0;
	height: 94px;
	background: url(../../assets/img/titleFive/ly_ser04.png) no-repeat left top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	left: 356px;
	top: 23px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.us-service .ser-proc .ser-curve4.move {
	width: 94px
}

.us-service .ser-proc .ser-step5 {
	left: 446px;
	top: 1px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt5 {
	left: 438px;
	top: -65px;
	-webkit-transition: all 0.5s 2s;
	-moz-transition: all 0.5s 2s;
	-o-transition: all 0.5s 2s;
	-ms-transition: all 0.5s 2s;
	transition: all 0.5s 2s;
}

.us-service .ser-proc .ser-curve5 {
	width: 0;
	height: 93px;
	background: url(../../assets/img/titleFive/ly_ser05.png) no-repeat left top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	left: 487px;
	top: 23px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.us-service .ser-proc .ser-curve5.move {
	width: 93px;
}

.us-service .ser-proc .ser-step6 {
	left: 560px;
	top: 112px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt6 {
	left: 618px;
	top: 108px;
	-webkit-transition: all 0.5s 2.4s;
	-moz-transition: all 0.5s 2.4s;
	-o-transition: all 0.5s 2.4s;
	-ms-transition: all 0.5s 2.4s;
	transition: all 0.5s 2.4s;
}

.us-service .ser-proc .ser-curve6 {
	width: 0;
	height: 96px;
	background: url(../../assets/img/titleFive/ly_ser06.png) no-repeat left top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	left: 580px;
	top: 152px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.us-service .ser-proc .ser-curve6.move {
	width: 224px;
}

.us-service .ser-proc .ser-step7 {
	left: 782px;
	top: 112px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.step-txt7 {
	left: 780px;
	top: 50px;
	-webkit-transition: all 0.5s 2.8s;
	-moz-transition: all 0.5s 2.8s;
	-o-transition: all 0.5s 2.8s;
	-ms-transition: all 0.5s 2.8s;
	transition: all 0.5s 2.8s;
}

.step-txt {
	opacity: 0;
}

.step-txt.move {
	opacity: 1;
}

.step-txt .cn {
	color: #000;
}

.step-txt .en {
	color: #969696;
	font-size: 14px;
	margin-top: 5px;
	white-space: nowrap
}

/* .us-service .pra{
	opacity: 1!important;
} */
</style>
