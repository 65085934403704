import Vue from "vue";
import VueRouter from "vue-router";
import titleOne from "../views/titleOne.vue";
import titleTwo from "../views/titleTwo.vue";
import titleThree from "../views/titleThree.vue";
import titleThree_1 from "../views/titleThree/titleThree_1.vue";
import titleThree_2 from "../views/titleThree/titleThree_2.vue";
import titleThree_3 from "../views/titleThree/titleThree_3.vue";
import titleThree_4 from "../views/titleThree/titleThree_4.vue";
import titleThree_5 from "../views/titleThree/titleThree_5.vue";
import titleThree_6 from "../views/titleThree/titleThree_6.vue";
import titleFore from "../views/titleFore.vue";
import titleFive from "../views/titleFive.vue";
import titleFive_1 from "../views/titleFive/titleFive_1.vue";
import titleFive_2 from "../views/titleFive/titleFive_2.vue";
import titleFive_3 from "../views/titleFive/titleFive_3.vue";
import titleSix from "../views/titleSix.vue";
import titleSeven from "../views/titleSeven.vue";
import titleEight from "../views/titleEight.vue";
import titleNine from "../views/titleNine.vue";

import AnLi_1 from "@/views/AnLi/AnLi_1.vue";
import AnLi_2 from "@/views/AnLi/AnLi_2.vue";
import AnLi_3 from "@/views/AnLi/AnLi_3.vue";


import AnLi_4 from "@/views/AnLi/AnLi_4.vue";
import AnLi_5 from "@/views/AnLi/AnLi_5.vue";
import AnLi_6 from "@/views/AnLi/AnLi_6.vue";
// import RealStory from "../views/111111/titleThree_1.vue";
// import jinrong from "../views/111111/titleThree_2.vue";
// import zhijiao from "../views/111111/titleThree_3.vue";
// import dianshang from "../views/111111/titleThree_4.vue";








// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "titleOne",
		component: titleOne,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleTwo",
		name: "titleTwo",
		component: titleTwo,
		meta: {
			title: "关于我们",
		},
	},
	{
		path: "/titleThree",
		name: "titleThree",
		component: titleThree,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleFore",
		name: "titleFore",
		component: titleFore,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleFive",
		name: "titleFive",
		component: titleFive,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleSix",
		name: "titleSix",
		component: titleSix,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleSeven",
		name: "titleSeven",
		component: titleSeven,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleEight",
		name: "titleEight",
		component: titleEight,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleNine",
		name: "titleNine",
		component: titleNine,
		meta: {
			title: "EupWire",
		},
	},

	{
		path: "/titleThree_1",
		name: "titleThree_1",
		component: titleThree_1,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleThree_2",
		name: "titleThree_2",
		component: titleThree_2,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleThree_3",
		name: "titleThree_3",
		component: titleThree_3,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleThree_4",
		name: "titleThree_4",
		component: titleThree_4,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleThree_5",
		name: "titleThree_5",
		component: titleThree_5,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleThree_6",
		name: "titleThree_6",
		component: titleThree_6,
		meta: {
			title: "EupWire",
		},
	},

	{
		path: "/titleFive_1",
		name: "titleFive_1",
		component: titleFive_1,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleFive_3",
		name: "titleFive_3",
		component: titleFive_3,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/titleFive_2",
		name: "titleFive_2",
		component: titleFive_2,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/AnLi_6",
		name: "AnLi_6",
		component: AnLi_6,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/AnLi_5",
		name: "AnLi_5",
		component: AnLi_5,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/AnLi_4",
		name: "AnLi_4",
		component: AnLi_4,
		meta: {
			title: "EupWire",
		},
	},
	
	
	{
		path: "/AnLi_3",
		name: "AnLi_3",
		component: AnLi_3,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/AnLi_2",
		name: "AnLi_2",
		component: AnLi_2,
		meta: {
			title: "EupWire",
		},
	},
	{
		path: "/AnLi_1",
		name: "AnLi_1",
		component: AnLi_1,
		meta: {
			title: "EupWire",
		},
	},
];
// title

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
	
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
