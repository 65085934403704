<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2  :bannerImg="Img1" :one="$i18n.t('titleEight')"></banner2>
		<!-- 图左文字右，带副标题 -->
		<div class="box">
			<imgAndText :dWidth="1200" disType="flex">
				<!-- 文本插槽，文本放在这个标签内 -->
				<template v-slot:titleSlot>
					<!-- 可以通过再套个div复写样式 -->
					<div class="titleSlot">{{ $i18n.t("titleEight") }}{{ $i18n.t("fw") }}</div>
				</template>
				<!-- 文本插槽，文本放在这个标签内 -->

				<template v-slot:textSlot>
					<!-- 还可以再增加副标题 -->
					
					
					<div class="textTitle" style="margin-right: 100px;">
						<div class="textContent">{{ $i18n.t("titleEight_list1_text") }}</div>
						<div class="textContent" style="margin-top: 0;">{{ $i18n.t("titleEight_list2_text") }}</div>
					</div>
				</template>
				<!-- 图片插槽，图片放在这个标签内 -->

				<template v-slot:imgSlot>
					<!-- 图片与文字的间距通过图片的margin控制 -->
					<img style="height: 300px;margin-right: 60px;" src="../assets/img/ctcz.png" alt="" />
				</template>
			</imgAndText>
		</div>

		<!-- 服务流程 -->
		<div class="box">
			<div class=" flow" style="width: auto;">
				<p class="rowTitle" style="margin-bottom: 10px;">{{$i18n.t('serviceProcess')}}</p>
				<!--  column-gap: 点与点之间的间距 160px -->
				<div class="lcArr" style=" column-gap: 100px;">
					<div class="hengLine"></div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
						<div class="lcCircle">01</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('serviceProcess_titleEight_list1')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span :style="$i18n.getLocale() == 'en'?'top: -45px;':''" >{{$i18n.t('serviceProcess_titleEight_list2')}}</span>
						</div>
						<div class="lcCircle">02</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							沟通客户需求 -->
						</div>
						<div class="lcCircle">03</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('serviceProcess_titleEight_list3')}}</span>
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<div class="lcLine"></div>
							<span :style="$i18n.getLocale() == 'en'?'top: -80px;':'top: -45px;'">{{$i18n.t('serviceProcess_titleEight_list4')}}</span>
						</div>
						<div class="lcCircle">04</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<!-- <div class="lcLine"></div>
							<span>沟通客户需求</span> -->
						</div>
					</div>
					<div class="lcItem">
						<div class="lcTitleTop">
							<!-- <div class="lcLine"></div>
							<span>沟通客户需求</span> -->
						</div>
						<div class="lcCircle">05</div>
						<!-- lcTb 在下面 -->
						<div class="lcTitleBotton">
							<div class="lcLine"></div>
							<span>{{$i18n.t('serviceProcess_titleEight_list5')}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 我们的优势 -->
		<div class="box">
			<div class=" flow">
				<p class="rowTitle" style="margin-bottom: 50px;">{{$i18n.t('ourStrengths')}}</p>
				<div class="ys-box">
					<div class="ys">
						<!-- 01 -->
						<div class="ys-item" style="background-color: whitesmoke;">
							<span class="ys-title">
								01
								 
							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_list1')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
						<!-- 02 -->
						<div class="ys-item">
							<span class="ys-title">
								02
								 
							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_list2')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
						<!-- 0304 -->
						<div class="ys-item ys-item-col2">
							<img data-v-72e92cc0=""
								src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt="" srcset=""
								style="width: 100%;height: 100%;">
						</div>
						<!-- 0506 -->
						<div class="ys-item ys-item-col2">
							<img data-v-72e92cc0=""
								src="https://image.proexpo.cc/storage/1781/tpl/my_27/tmp/mark_img2.jpg" alt="" srcset=""
								style="width: 100%;height: 100%;">
						</div>
						<!-- 03 -->
						<div class="ys-item ys-item-dark">
							<span class="ys-title">
								03
								 
							</span>
							<div class="yl-body">
								<div class="yl-content" style="">{{$i18n.t('ourStrengths_titleEight_list1')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
						<!-- 04 -->
						<div class="ys-item">
							<span class="ys-title">
								04
								 
							</span>
							<div class="yl-body">
								<div class="yl-content">{{$i18n.t('ourStrengths_titleEight_list2')}}</div>
								<!-- <div class="yl-tips">7天、24小时在线提供无时差、无语言交流障碍的高效服务</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		
		
		<!-- 品牌案例 -->
		<div class="box">
			<div class="flow" style="margin-bottom: 40px;">
				<p style="margin-bottom: 35px; margin-top: 40px;">{{$i18n.t('brandCase')}} </p>
				<div class="al-box">
					<div class="al">
						<div @click="$router.push('/AnLi_1')">
							<img src="../assets/images/AnLi/1-1.jpg" alt="" srcset="" >
								<div>
									<div>{{$i18n.t('bc1_t')}}</div>
									<div>{{$i18n.t('bc1_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_2')">
							<img src="../assets/images/AnLi/2-1.jpg" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc2_t')}}</div>
									<div>{{$i18n.t('bc2_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_3')">
							<img src="../assets/images/AnLi/3-1.png" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc3_t')}}</div>
									<div>{{$i18n.t('bc3_b')}}</div>
								</div>
						</div>
					</div>
					<div class="al">
						<div @click="$router.push('/AnLi_4')">
							<img src="../assets/images/AnLi/4-1.png" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc4_t')}}</div>
									<div>{{$i18n.t('bc4_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_5')">
							<img src="../assets/images/AnLi/5-1.png" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc5_t')}}</div>
									<div>{{$i18n.t('bc5_b')}}</div>
								</div>
						</div>
						<div @click="$router.push('/AnLi_6')">
							<img src="../assets/images/AnLi/6-1.jpg" alt="" srcset="">
								<div>
									<div>{{$i18n.t('bc6_t')}}</div>
									<div>{{$i18n.t('bc6_b')}}</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<footer2></footer2>
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
// 引入组件
import imgAndText from "../components/models/imgAndText.vue";

export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		imgAndText,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../assets/img/keji/矿山banner1.png"),
			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},
	methods: {},
};
</script>

<style>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}

/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}

/*字体 文本居左*/
ul {
	list-style: none;
}

/*去ul li 小点*/
img {
	border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}

/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}

/*清浮动*/
a {
	color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}

/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}

.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}

.Crumbs div {
	line-height: 46px;
}

.Crumbs span {
	color: #000000;
}

/* 新闻详情 */
.NewsDetails_cont {
	width: 1200px;
	margin: 0 auto;
}

.NewsDetails_cont_title>h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}

.NewsDetails_cont_title img {
	vertical-align: middle;
}

.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}

.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}

.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}

.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}

.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}

.NewsDetails_cont_footer {
	padding: 0 40px;

	line-height: 68px;
	width: 1120px;
	height: 68px;
	background: #f8f8f8;
	display: flex;
	justify-content: space-between;
}

.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}

.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}

.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}

.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}

.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}

.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}

.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}


</style>
