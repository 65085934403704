export const messages = {
    en: {
        message: {
            fw:" Service",
            currentLocation:"current location ：",
            // 导航
            titleOne: 'Home Page',
            titleTwo: 'About Us',
			/* 真实故事 start */
			ourStrengths: "Our Advantages",
			ourStrengths_list1:"24 hours a day, 7 days a week online to provide efficient services without time difference and language communication barriers",
			ourStrengths_list2:"1000+ Creative Cases, 2000+ Translations, People-oriented, Efficient Customer Service",
			ourStrengths_list3:"Quality strategies and unlimited ideas from journalists at 200+ leading news agencies around the world",


			brandCase:"Testimotionals",


			serviceProcess: "Service Process",
			serviceProcess_list1: "Communicate customer needs",
			serviceProcess_list2: "Completion of delivery",

			serviceInfo: 'Service Introduction',
            titleThree: 'True Stories',

			titleThree_1:'Content Creation',
			titleThree_1_list1_text:"Our content production team provides global clients with creative content services, including but not limited to naming, advertising slogans, and promotional language production. We deliver creative content outputs tailored to meet your specific needs.",
			ourStrengths_th1_list1:"In-depth industry analysis to provide global clients with locally adapted word nomenclature",
			serviceProcess_th1_list1: "Provide naming direction and creative strategy for clients",
			serviceProcess_th1_list2: "Communicate naming proposals with client meetings",
			serviceProcess_th1_list3: "Submission of the first draft",
			serviceProcess_th1_list4: "Modification of customer comments",

			titleThree_2:'Article Writing',
			titleThree_2_list1_text:"The content production center of EUP provides high-quality manuscript creation services for global customers. Customers can choose any language, and our company provides free 1-to-1 free communication services without time difference, so as to customize your manuscripts for you.",
			ourStrengths_th2_list1:"Professional services, confirm the outline in advance, and provide free revision services for global customers until customer satisfaction",
			serviceProcess_th2_list1: "Confirming the outline and creative ideas with the client and matching them with the right journalists",
			serviceProcess_th2_list2: "Revise and finalize the outline",
			serviceProcess_th2_list3: "Writing and communicating first drafts",
			serviceProcess_th2_list4: "Provide revisions and finalize",


			titleThree_3:'Photography',
			titleThree_3_list1_text:"With 100+ quality photojournalists, 5,000+ photographers and 1,000+ cloud-based image processors around the world, EUP is able to provide customers with shooting services for products, meetings, spaces and contents according to customers' needs, and provide editing services free of charge.",
			ourStrengths_th3_list1:"120+ photojournalists from all over the world provide global shooting services without borders.",
			serviceProcess_th3_list1: "Confirmation of shooting requirements and selection of packages with clients",
			serviceProcess_th3_list2: "Arrange for a suitable photographer or photojournalist",
			serviceProcess_th3_list3: "Delivery of original drawings within three days",
			serviceProcess_th3_list4: "Provide optimized image delivery",


			titleThree_4:'Video Production',
			titleThree_4_list1_text:"EUP global photojournalist and quality photographer team focuses on commercial video filming and production, providing customers with commercial video filming and production content production, we provide 1 to 1 free consultant to communicate your needs, provide you with professional video strategy production services, with skillful and superb professional technology, exploring, pondering and discovering the art of light and shadow, from creative planning, make-up styling, actor dubbing to screenwriting and directing, Photography, post-production, three-dimensional packaging of high-quality, high-efficiency, high-level professional team. ",
			serviceProcess_th4_list1: "Confirmation of shooting requirements and selection of packages with clients",
			serviceProcess_th4_list2: "Arrange for a suitable photographer or photojournalist",
			serviceProcess_th4_list3: "Delivery of original drawings within three days",
			serviceProcess_th4_list4: "Provide optimized image delivery",


			titleThree_5:'Production and Management of Image Archives',
			titleThree_5_list1_text:"EUP provides high-quality image archive production and management services to convert paper archives and other forms of original materials into electronic image files that can be efficiently stored, managed, retrieved and shared. Services include:",
			titleThree_5_list2_text:"1. Image storage and management: Through digitization, the original information is converted into electronic image files, which are classified, archived and stored. The product provides a variety of storage methods to ensure the safety and reliability of data.",
			titleThree_5_list3_text:"2. Image retrieval and browsing: supports image retrieval through various ways, providing flexible and fast query results. Meanwhile, users can browse, print and share image files online to improve the efficiency of file utilization.",
			titleThree_5_list4_text:"3. Image quality control: the product supports the adjustment and optimization of image clarity, brightness, contrast and other parameters to ensure that the image quality meets the requirements and meets the needs of enterprises.",
			ourStrengths_th5_list1:"120+ photojournalists worldwide, providing professional shooting services",
			ourStrengths_th5_list2:"International creative planning team provides high quality script output and on-site shooting service.",
			ourStrengths_th5_list3:"Experience in the archival and imaging management industry",
			serviceProcess_th5_list1: "Communicate client needs and arrange for the right photographer or photojournalist",
			serviceProcess_th5_list2: "Communicate time in advance to work with clients on shoots",
			serviceProcess_th5_list3: "Regular video updates and archiving",
			serviceProcess_th5_list4: "Regularly communicate with clients about shooting ideas",
			serviceProcess_th5_list5: "Regularly update customer image file system",




			titleThree_6:'Full case activity planning',
			/* 真实故事 end */



            titleFore: 'Advertorial Publication',
			serviceProcess_titleFore_list1:"Matching with dedicated media consultants to communicate with clients and acquire scripts",
			serviceProcess_titleFore_list2:"Matching clients with appropriate media communication programs",
			serviceProcess_titleFore_list3:"Customer Confirmation, Submit Order",
			serviceProcess_titleFore_list4:"review a manuscript",
			serviceProcess_titleFore_list5:"Provide promotional listings and free public opinion monitoring",
			serviceProcess_titleFore_list6:"Testimonials",
			ourStrengths_titleFore_list1:"High speed",
			ourStrengths_titleFore_list2:"Quickly and accurately deliver information to designated online media in seconds, allowing information to spread with the fastest efficiency.",
			ourStrengths_titleFore_list3:"More authoritative",
			ourStrengths_titleFore_list4:"Promote in the form of news, quickly and cost effectively enhance the image of the enterprise and its products, and establish a positive corporate image",
			ourStrengths_titleFore_list5:"More easily searchable",
			ourStrengths_titleFore_list6:"Provide the optimal solution for search engines",
			ourStrengths_titleFore_list7:"Wide coverage",
			ourStrengths_titleFore_list8:"We can continuously promote articles according to your regional needs and publish them on precise media in various industries",
			



            titleFive: 'Overseas Abroad',
			titleFive_1:'public relations program',
			titleFive_1_list1_text: "In response to the client's communication objectives and needs, EUP plans and customizes overseas communication programs for the client through a fan-shaped communication strategy that takes into account the media rules and audience habits of different countries.",
			serviceProcess_tf1_list1:"Recognizing overseas communication needs and objectives",
			serviceProcess_tf1_list2:"Analyzing the current situation and background of the client company's overseas background",
			serviceProcess_tf1_list3:"Development of targeted communication programs",
			serviceProcess_tf1_list4:"Project implementation",
			serviceProcess_tf1_list5:"Monitoring summary",

			titleFive_2:'KOL Services',
			tf2_title_text1:"KOL Services",
			tf2_title_text2:"EUP provides customers with high-quality global KOL solutions, helping brand owners invite industry opinion leaders in one stop shop. A marketing approach that promotes a brand, product, or service through the content creation and sharing of these influencers on social media platforms. These influencers may be celebrities, bloggers, opinion leaders, or industry experts on social media. They interact with fans through mainstream overseas social media platforms such as YouTube, Instagram, TikTok, etc., and have a significant impact on their consumption decisions, thereby accurately reaching the target audience and increasing brand awareness.",
			tf2_b_t1:"Numerous KOL resources",
			tf2_b_text1:"Facebook Instagram Tiktok Youtube Twitter and other platforms",
			tf2_b_t2:"Rich KOL Types",
			tf2_b_text2:"KOL cooperation in technology, fashion and life, outdoor travel, etc.",
			tf2_b_t3:"Wide coverage area",
			tf2_b_text3:"Europe, America, South Africa, Middle East, UAE, Southeast Asia, Asia Pacific Coverage",
			tf2_hz_title_text1:"How KOLs Empower Businesses",
			tf2_hz_title_text2:"Overseas social media celebrities personally participate in client's events and provide on-site social media synchronization.",
			tf2_hz_text1:"Attending brand events",
			tf2_hz_text2:"Social media matrix account linkage",
			tf2_hz_text3:"Product seeding",
			tf2_hz_text4:"Brand Interaction",
			
			tf2_service_text1:"Understand the customer's industry, budget, and requirements",
			tf2_service_text2:"Communicate with clients and provide customized solutions",
			tf2_service_text3:"Implement and implement solutions based on customer needs",
			tf2_service_text4:"Provide closing reports to clients",


			titleFive_3:'Overseas Press Release',
			titleFive_3_list1_text:"EUP has global quality 6W+ media resources, cooperation specializing in global media communication and brand strategy consulting, and strategic cooperation with top media and news agencies around the world, including the Associated Press (AP) of the United States, Agence France-Presse (AFP) of France, Reuters of the United Kingdom, Japan's Current Affairs News Agency (Jiji Press), South Korea's Yonhap News Agency (Yonhap), the U.S. Bloomberg News Agency (Bloomberg), Italy's Ansa News Agency (ANSA), Turkey's Anadolu News Agency (Anadolu News Agency), Spain's Iberia News Agency (Ibiza News Agency) and other hundreds of news agencies. With its own media station, global virtual reporter network, media distribution and dissemination can cover more than 200 countries in five continents, synchronized with more than 200 languages, covering more than 300 countries and regions, we can choose the appropriate distribution channel according to the needs and budget of the enterprise, to ensure that the manuscripts can be widely disseminated and attention in the target market.",

			ourStrengths_tf3_list1:"Exclusive journalist resources and first-hand overseas 6W+ media resources",
			ourStrengths_tf3_list2:"Specialized services to provide optimal communication paths and strategies for global clients.",
			service_tf_list1:"Identify overseas communication needs and goals",
			service_tf_list2:"Analyze oversea background and status of clients" ,
			service_tf_list3:"Develop a targeted communication plan",
			service_tf_list4:"Project execution",
			service_tf_list5:"Monitoring Summary",
			
			

            titleSix: 'Journalist Invitation',
			titleSix_list1_text:"EUP provides overseas journalist invitation service, which is designed to help enterprises, organizations or individuals who need to contact and cooperate with international media to build a bridge to communicate with overseas journalists, and to help your PR department and news center more conveniently invite overseas journalists to participate in activities, interviews and other forms of cooperation. After booking the service, you can specify the country and language, docking a media consultant, to provide you with customized one-stop overseas journalist invitation program. According to your needs and objectives, we will screen suitable overseas journalists and assist in arranging interview time, place and specific matters. Meanwhile, EUP also provides translation and communication support to ensure smooth communication between both parties in the process of exchange and accelerate business development.",
			ourStrengths_titleSix_list1:"200+ Global Journalists, 200+languages, 6W+ Media Resources",
			ourStrengths_titleSix_list2:"Specialized services to provide optimal communication paths and strategies for global clients.",
			serviceProcess_titleSix_list1:"Clients identify overseas communication needs",
			serviceProcess_titleSix_list2:"Designate the launch communication program according to the client's needs",
			serviceProcess_titleSix_list3:"Customer provides relevant information in English",
			serviceProcess_titleSix_list4:"material processing",
			serviceProcess_titleSix_list5:"Media relations",
			serviceProcess_titleSix_list6:"on-site implementation",
			serviceProcess_titleSix_list7:"Monitoring summary",




            titleSeven: 'Sentiment Monitoring',



            titleEight: 'Product Awards',
			titleEight_list1_text:"As a global translation and communication organization, EUP is committed to providing clients with a full range of list services. Its list service not only covers a wide range of fields and industries, but also emphasizes the accuracy and timeliness of data, aiming to help clients understand the market dynamics and competitive situation in a timely manner.",
			titleEight_list2_text:"EUP can provide industry lists, Product Awardss and media lists for clients with targeted analysis for global clients.",
			ourStrengths_titleEight_list1:"2000+ Analysts, Global Industry Recognition",
			ourStrengths_titleEight_list2:"Professional services to provide the optimal business community for global clients",
			serviceProcess_titleEight_list1:"Communicate customer needs",
			serviceProcess_titleEight_list2:"Clarify the type of list",
			serviceProcess_titleEight_list3:"Provide list program",
			serviceProcess_titleEight_list4:"Execute list strategies and programs",
			serviceProcess_titleEight_list5:"List to receive awards",




            titleNine: 'Integrated Marketing',
            // 关于我们
            SyWepWire:'Company Profile',
            WepWire: "Shanghai EUP Science and Technology Co., Ltd,which is a leading global public relations and marketing communication agency,is established in 2019 and based in Shanghai. We have a network of journalists from 150 countries worldwide, with over 200 collaborating journalists. Our partners include internationally renowned news agencies, global high-quality media, and global influencers. We provide media distribution, communication, and brand marketing services, covering over 150 countries across five continents. Our global high-quality media resources reach over 60000, and we simultaneously advertise in more than 200 languages.EUP has established an economic window for the world to connect with China, and built a media hub for China's voice and brand to the outside world.Shanghai EUP Science and Technology Co., Ltd,which is a leading global public relations and marketing communication agency,is established in 2019 and based in Shanghai. We have a network of journalists from 150 countries worldwide, with over 200 collaborating journalists. Our partners include internationally renowned news agencies, global high-quality media, and global influencers. We provide media distribution, communication, and brand marketing services, covering over 150 countries across five continents. Our global high-quality media resources reach over 60000, and we simultaneously advertise in more than 200 languages.EUP has established an economic window for the world to connect with China, and built a media hub for China's voice and brand to the outside world.",
			qywh:"Corporate Culture",
            qyln:"Corporate philosophy",
            qylnWz:"Promote social prosperity and enhance people's happiness",
            qyyj:"Corporate Vision",
            qyyjWz:"Assist in talent diversification and facilitate business globalization",
            qykh:"Corporate Slogan",
            qykhWz:"Amplify the voice of China, foster connections between domestic and foreign businesses, and effectively communicate the trends and events of our time",

            medium:"Countries",
            yy:"Language",
            hyzj:"Journalists",
            yzmt:"Media channles",

            // 记录与创作
            TwoNy:'Documentation and creation on a real basis is characteristic of the current online real-time text and image services provided by the content creation team of EUP for global customers. We offer 1-on-1 connections with top-notch media professionals worldwide, who will precisely analyze your content production needs and provide you with high-quality and efficient content production services.',
            EntryCreation_1:'Content Creation',
            EntryCreation_2:'Our content production team provides global clients with creative content services, including but not limited to naming, various types of advertising slogans, and promotional language production. With a global perspective and professional service attitude, we deliver creative content outputs in the form of articles, tailored to meet your specific needs.',
            DraftWriting_1:'Article Writing',
            DraftWriting_2:'Our content production team offers high-quality content creation services to global clients. You can freely choose the language of your choice, and we provide free 1-on-1 communication services regardless of , allowing us to tailor your content specifically to your needs.',
            PhotographyServices_1:'Photography Services',
            PhotographyServices_2:'Translators Association has a network of 100+ high-quality photojournalists, 5000+ photographers, and 1000+ cloud image processing specialists worldwide. We are capable of providing clients with photography services for products, conferences, spaces, and content based on their specific needs. Additionally, we offer free editing services.',
            video_1:'Video Production Services',
            video_2:'Translators Association\'s global team of photojournalists and high-quality photographers specialize in commercial video production. We offer customized content production for commercial video shoots, providing 1-on-1 free consultation to understand your specific requirements. Our professional video strategy production services are backed by skilled expertise in exploring the art of light and shadow, covering aspects such as creative planning, makeup styling, voice-over, scriptwriting, directing, cinematography, post-production, and 3D packaging. Our company is equipped with advanced live shooting and post-production visual effects equipment, including SGI graphics workstations and non-linear editing devices. We possess professional-grade high-definition recording equipment such as Sony 970, Panasonic AJ-910, Sony HDWF900R, SONY EX1R, Z5C, Z7C, Canon 5D Mark II, 7D, 60D, and various other models for high-definition and standard-definition photography and videography. For high-definition shoots, we provide professional auxiliary equipment such as boom arms, tracks, cannons, and camera lighting to assist clients in discovering and creating brand value.',
            Production_1:'Production and management of image archives',
            Production_2:'EUP provides high-quality image archive production and management services for government and corporate clients. We specialize in converting physical archives and other forms of original materials into electronic image files, ensuring efficient storage, management, retrieval, and sharing. Our services enable businesses to transform their paper-based archives into digital formats, facilitating easy access and collaboration while ensuring the security and integrity of the data.',
            Production_2_1:'1. Image Storage and Management: Through digital processing, we convert the paper-based archives, photos, videos, and other original materials into electronic image files, and classify, archive, and store them. Our product offers various storage options such as local disk storage, server storage, and cloud storage, ensuring the security and reliability of the data. Image retrieval and browsing: supports image retrieval through various methods such as keywords, dates, and document types, providing flexible and fast query results. At the same time, users can browse, print, and share image files online, improving the efficiency of archive utilization.',
            Production_2_2:'2. I Image Retrieval and Browsing: We support image retrieval using various methods such as keywords, dates, and document types, providing flexible and fast query results. Users can conveniently browse, print, and share image files online, improving the efficiency of archive utilization.',
            Production_2_3:'3. Image Quality Control: Our product supports adjustments and optimizations of parameters such as image clarity, brightness, contrast, and more, ensuring that the image quality meets the required standards and meets the usage needs of our clients.',

            // 软文发布
            rwfbfw:"Soft Article Publishing Service",
            contentOneWa:"One Stop Service",
            contentOne: "EUP provides soft article publishing services, offering a one-stop comprehensive marketing solution for global clients. Our soft article publishing services cover the entire process from market research, theme planning, content writing to media distribution. Our professional team conducts in-depth research on client needs and market environments, carefully planning the themes and angles of the soft articles based on the preferences and demands of the target audience. Additionally, we have experienced writers who can create soft article content that combines advertising qualities with reading value, ensuring that the articles can capture readers' attention and convey clear messages.",
            contentTwoWz:"Customized Service",
            contentTwo:"EUP has established extensive cooperation with numerous mainstream media, industry websites, blog platforms, forums, and social media platforms, among others, providing clients with diversified publishing channels. We will formulate appropriate publishing strategies based on clients' needs and budgets to ensure that the soft articles reach the target audience and achieve positive dissemination effects. After the publication, we will promptly provide clients with tracking and feedback on the effectiveness. We will regularly collect and analyze data after the soft articles are published, including metrics such as readership, clicks, conversion rates, etc., in order to provide clients with detailed performance reports. This will help clients better understand the effectiveness of soft article marketing and make adjustments and optimizations based on the actual situation.",
            // 海外传播
            OverseasPublishingWz:"Overseas Publishing",
            OverseasPublishing:"EUP has a global network of over 60,000 high-quality media resources and specializes in global media communication and brand strategy consulting. We have established strategic partnerships with top media outlets and news agencies worldwide, including the Associated Press (USA), Agence France-Presse (France), Reuters (UK), Kyodo News (Japan), Yonhap News Agency (South Korea), Bloomberg (USA), ANSA (Italy), Anadolu Agency (Turkey), and EFE (Spain), among hundreds of others. With our own media platforms and a global network of virtual reporters, our media distribution can cover over 200 countries in all five continents, delivering content in over 200 languages. We can tailor the publication channels based on the specific needs and budget of companies to ensure extensive coverage and attention in the target market. Additionally, we offer tracking and feedback services to monitor the effectiveness of overseas publications. By collecting and analyzing post-publication data, we can help companies understand the impact of their releases, including exposure, clicks, conversion rates, and other metrics, enabling them to adjust and optimize their publication strategies based on actual results.\n",
            journalistInvitationWz:"Journalist Invitation",
            journalistInvitation:"EUP provides overseas journalist invitation services aimed at assisting companies, institutions, or individuals who need to connect and collaborate with international media. This service helps to establish a bridge of communication with overseas journalists, enabling your PR department and news center to conveniently invite them for events, interviews, or other forms of collaboration. Upon booking this service, you can specify the country and language preferences and be assigned a media consultant who will provide a customized one-stop solution for your overseas journalist invitation needs. Based on your requirements and objectives, suitable overseas journalists will be selected, and assistance will be provided in arranging interview schedules, locations, and other specific details. Additionally, EUP offers translation and communication support to ensure smooth and effective communication between both parties, avoiding misunderstandings and barriers.",

            // 业务服务
            hdch_1:'Event Planning',
            hdch_2:'EUP creative team offers boundaryless, creative, and innovative full-service event planning and offline execution for global clients. We have a team of advertising material designers and builders both domestically and internationally, capable of providing services such as press conferences, annual meetings, conferences and forums, competitions, anniversaries, cocktail parties, award ceremonies, groundbreaking ceremonies, real estate events, cultural activities, and flash mobs.',
            yqjc_1:'Public opinion monitoring',
            yqjc_2_1:'Government and enterprise public opinion big data service platform',
            yqjc_2_2:'Comprehensive Monitoring and Insights into Public Opinion',
            yqjc_2_3:'Official data support',
            yqjc_2_4:'Real time monitoring of the entire network information for 7 * 24 hours',
            yqjc_2_5:'Rapid warning and precise identification of risks',
            yqjc_2_6:'Intelligent analysis and insight into public opinion trends',
            zhyx_1:'Integrated marketing services',
            zhyx_2:'EUP is committed to providing high-quality integrated marketing services to global clients. Our integrated marketing services cover various aspects, including market research, brand positioning, content creation, and multi-channel promotion. With our extensive industry experience and professional team, we can tailor the most suitable marketing solutions for clients, ensuring accurate communication of information and leaving a lasting brand impression. In terms of translation and localization, we have a dedicated team of translators and technical support to handle translation needs in various languages and cultural backgrounds. We emphasize the accuracy and cultural adaptability of translations to ensure that clients messages are accurately conveyed in different countries and regions, thereby enhancing brand influence and market competitiveness.',
            cpbd_1:'Product Ranking',
            cpbd_2:'As a global translation and communication agency, EUP is committed to providing comprehensive ranking services to clients. Its ranking services cover multiple fields and industries, emphasizing the accuracy and timeliness of data, aiming to help clients stay informed about market dynamics and competitive situations in a timely manner. EUP can conduct targeted analysis for global clients and provide industry rankings, product rankings, and media rankings.',
            cpbd_2_1:'1. Strong expertise: EUP has a professional team of translators and communicators who have in-depth understanding and research in various industries and fields. Therefore, its ranking services can provide clients with professional and targeted information and data tailored to their specific needs.',
            cpbd_2_2:'2. Accurate data: EUP focuses on data collection and organization, obtaining authoritative data sources through various channels and conducting rigorous verification and screening. Additionally, EUP utilizes advanced data analysis tools and methods to ensure the accuracy and reliability of the ranking data.',
            cpbd_2_3:'3. Real-time updates: EUP ranking services can track market dynamics and industry developments in real-time, ensuring timely updates of the ranking data. Through TransUnion\'s ranking services, clients can gain a better understanding of market trends, industry developments, and competitors, providing strong support and references for business decision-making and planning. Additionally, EUP\'s ranking services can enhance clients\' brand image and market influence, strengthening their competitiveness and voice in the industry.',


            // 联系我们
            contact: 'Contact Us',
            yx1:'Contact email',
            yx2:'E-mail for draft submission',
            Address_1:'Address',
            Address_2:"Room 413-7, South Building B1, DaNing Center Plaza, No. 718 ,Lingshi Road, Jing'an District, Shanghai",
            number:'Contact phone number',
            WxGzh:'WeChat Official Accounts',
            eup1:'EUP Information',
            eup2:'EUP Media',

            // 优势
            OurAdvantages_1:"24/7 online service with no time difference and efficient communication regardless of language barriers.",
            OurAdvantages_2:"200+  globally renowned professionals offering targeted communication services",
            OurAdvantages_3:"2000+ industry experts to offer efficient advice for your business expansion and development.",
            OurAdvantages_4:"60,000+ high-quality media resources for comprehensive dissemination and distribution of your content.",
            OurAdvantages_5:"30+ communication platforms, 1000+ creative cases, premium strategies and unlimited creativity.",

            // 企业历程
            Rc_1:"Shanghai Yuanzhou Business Consulting Co., Ltd. (predecessor of EUP) was established to deliver high-quality translation and interpretation services to clients worldwide.",
            Rc_2:"Our company brought together a team of global language talents and established the Middle East and Europe Translation Business Branch, which has been operated independently.",
            Rc_3:"The company transformed its business into service trade, providing high-quality sourcing and exhibition representation services to global buyers, and has built a strong customer base and reputation.",
            Rc_4:"The company innovated its business by securing endorsements from non-profit organizations, gathering global talents to collaborate in the new era of communication.",
            Rc_5:"The company received accolades from global clients and commendation from higher-level government departments, and set a new industry benchmark in terms of communication channels and content production.",
            Rc_6:"EUP has been established and our trademark application has been completed.",
            EnterpriseDevelopment:"Enterprise development",

            // 这里是用来测试填充内容的文本
            test_text:"The company received accolades from global clients and commendation from higher-level government departments, and set a new industry benchmark in terms of communication channels and content production.The company received accolades from global clients and commendation from higher-level government departments, and set a new industry benchmark in terms of communication channels and content production.",


			bc1_t:"GAC goes out to sea to make a world impact",
			bc1_b:"Promote global sales of GS4 and promote global brand image of GAC.",


			bc2_t:"Midea's smart green energy management solution, MHELIOS, promotes a smarter, greener lifestyle.",
			bc2_b:"Thai press conference invitation, on-site reporter interviews, Agence France-presse...",


			bc3_t:"Beautiful 2008 East West Bank Classic – Doubles",
			bc3_b:"To organize photo exhibitions on beautiful mountains and rivers in China and the United States.",

			bc4_t:"The List of Presidents of Zambia attended the launch mode of the “Universal Access” project.",
			bc4_b:"The china-africa cooperation project, “10,000 villages”, aims to make satellite digital television available in 10,000 villages in...",

			bc5_t:"Overseas Red customer cases",
			bc5_b:"Overseas Kol (Key Opinion Leader) plays an increasingly important role in brand promotion and customer marketing.",

			bc6_t:"China's Road to economic development",
			bc6_b:"With the rapid development of China's economy, more and more international attention is focused...",

			AnLi6_h3_1:"Background",
			AnLi6_h3_1_1:"With the rapid development of China's economy, more and more international attention focused on this ancient land. Recently, a group of journalists from well-known European media came to China to explore the mystery of China's economic development and feel the powerful driving force of China's economic development.",
			AnLi6_h3_2:"The process of collecting wind",
			AnLi6_h3_2_1_1:"High-tech zone visit：",
			AnLi6_h3_2_1_2:"The press corps first came to China's high-tech Zone, where China's new economic development is a unique and effective path. In the high-tech zone, reporters witnessed the R & D and application of cutting-edge technologies such as new energy, artificial intelligence and biological manufacturing, and felt the vitality of China's scientific and technological innovation.",
			AnLi6_h3_2_2_1:"Enterprise inspection：",
			AnLi6_h3_2_2_2:"Subsequently, the press corps in-depth enterprise, with entrepreneurs, technology experts for in-depth exchanges. Through on-site visits and interviews, the journalists learned about the remarkable achievements made by Chinese enterprises in transformation and upgrading, innovation-driven and green development. At the same time, they also feel the innovative spirit of Chinese entrepreneurs, the spirit of hard work and the spirit of responsibility.",
			AnLi6_h3_2_3_1:"Policy interpretation：",
			AnLi6_h3_2_3_2:"During the gathering, the delegation also interviewed a number of Chinese government officials and experts and scholars to gain an in-depth understanding of the policy environment and strategic plans for China's economic development. They said the Chinese government has ensured stable economic growth and sustainable development by strengthening coordination and coordination among fiscal, monetary, employment, industrial, regional, scientific and technological, and environmental protection policies.",
			AnLi6_h3_2_4_1:"Integration of urban and rural areas：",
			AnLi6_h3_2_4_2:"The delegation also visited typical areas of China's urban-rural integration. Here, they see the results of urban-rural infrastructure connectivity, public service sharing, industrial synergy and feel the tremendous changes brought about by urban-rural integration.",
			AnLi6_h3_3:"Feel and harvest",
			AnLi6_h3_3_1_1:"The economy is developing rapidly：",
			AnLi6_h3_3_1_2:"The press corps was keenly aware of the rapid momentum of China's economic development. Whether in terms of scale, speed or quality, China's economy is showing strong momentum of development.",
			AnLi6_h3_3_2_1:"The concept of green development：",
			AnLi6_h3_3_2_2:"An important feature of China's economic development is that it is innovation-driven. The government and enterprises attach great importance to scientific and technological innovation and personnel training, through innovation to promote economic transformation and upgrading and high-quality development.",
			AnLi6_h3_3_3_1:"Remarkable achievements have been made in reform and opening up：",
			AnLi6_h3_3_3_2:"The Chinese government adheres to the concept of green development and promotes coordinated economic and environmental development. The reporters saw that in the process of China's economic development, the ecological environment has been effectively protected and improved.",
			AnLi6_h3_3_4_1:"Integration of urban and rural areas：",
			AnLi6_h3_3_4_2:"The press corps believes that the achievements of China's economic development can not be separated from the great practice of reform and opening up. Reform and opening-up has injected powerful impetus into China's economic development, making China an important participant in the world's factory and market.",
			AnLi6_h3_4:"Summary and prospect",
			AnLi6_h3_4_1:"Through this gathering event, the European press corps deeply felt the strong driving force and unique charm of China's economic development. They said that the successful experience of China's economic development is worth learning from. In the future, they will continue to follow the trends and trends of China's economic development and make positive contributions to the promotion of china-eu Economic and trade cooperation and cultural exchanges. At the same time, they also expect China to play a more important role in the global economy and inject new impetus into world economic development.\n",
        }
    },
    zh: {
        message: {
            fw:"服务",
            currentLocation:"当前位置 ：",
            // 导航
            titleOne: '首页',

            titleTwo: '关于我们',

			serviceProcess: "服务流程",
			serviceProcess_list1: "沟通客户需求",
			serviceProcess_list2: "完成交付",

			serviceInfo: '服务介绍',

			ourStrengths: '我们的优势',
			ourStrengths_list1:"7天、24小时在线提供无时差、无语言交流障碍的高效服务",
			ourStrengths_list2:"1000+创意案例，2000+翻译，以人为本，高效服务客户",
			ourStrengths_list3:"全球200+知名通讯社记者提供优质策略和无限创意",

			brandCase:"品牌案例",

            titleThree: '真实故事',

            titleThree_1:'词条创作',
			titleThree_1_list1_text:"译联社内容生产中心以全球化视野和专业化服务态度，为全球客户提供词条创作服务，包括但不仅限于起名、各类广告语、宣传语制作等创意类词条输出服务。",
            ourStrengths_th1_list1:"行业深度分析，为全球客户提供符合当地国情的词条命名",
			serviceProcess_th1_list1: "为客户提供命名方向和创意策略",
			serviceProcess_th1_list2: "与客户会议沟通命名提案",
			serviceProcess_th1_list3: "提交初稿",
			serviceProcess_th1_list4: "客户意见修改",

			titleThree_2:'通稿撰写',
            titleThree_2_list1_text:"译联社内容生产中心为全球客户提供优质的稿件创作服务，客户可以任意选定语种，我司提供免费1对1 免费无时差沟通服务，为您定制您的稿件。",
			ourStrengths_th2_list1:"专业服务，提前确认纲要，为全球客户提供免费修改服务，直至客户满意",
			serviceProcess_th2_list1: "和客户确认纲要和创作思路并匹配合适的记者",
			serviceProcess_th2_list2: "修改纲要并定稿",
			serviceProcess_th2_list3: "初稿撰写并沟通",
			serviceProcess_th2_list4: "提供修改并定稿",




			titleThree_3:'照片拍摄',
            titleThree_3_list1_text:"译联社全球 100+优质摄影记者，5000+摄影师，1000+云端图像处理师，能够根据客户需求为客户提供产品、会议、空间、内容的拍摄服务，免费提供剪辑服务。",
			ourStrengths_th3_list1:"全球120+摄影记者提供全球无国界拍摄服务",
			serviceProcess_th3_list1: "和客户确认拍摄需求及选择套餐",
			serviceProcess_th3_list2: "安排合适的摄影师或摄影记者",
			serviceProcess_th3_list3: "三天内完成原图交付",
			serviceProcess_th3_list4: "提供优化后图片交付",

			titleThree_4:'视频拍摄',
            titleThree_4_list1_text:"译联社全球摄影记者和优质摄影师团队专注于商业视频拍摄制作，为客户提供商业视频拍摄制作内容制作，我们提供 1 对 1 的免费顾问沟通您的需求，为您提供专业的视频策略制作服务，用娴熟精湛的专业技术，探索、思考并发掘光与影的艺术，从创意策划、化妆造型、演员配音到编剧导演、摄影、后期制作、三维包装的高素质、高效率、高水准的专业化队伍。",
			serviceProcess_th4_list1: "和客户确认拍摄需求及选择套餐",
			serviceProcess_th4_list2: "安排合适的摄影师或摄影记者",
			serviceProcess_th4_list3: "三天内完成原图交付",
			serviceProcess_th4_list4: "提供优化后图片交付",



			titleThree_5:'影像档案制作',
            titleThree_5_list1_text:"译联社提供优质的影像档案制作与管理服务，将纸质档案和其他形式的原始资料转化为电子影像文件，并进行高效存储、管理、检索和共享。服务包括：",
			titleThree_5_list2_text:"1. 影像存储与管理：通过数字化处理，将原始资料转换为电子影像文件，并进行分类、归档和存储。产品提供多种存储方式，确保数据的安全可靠。",
			titleThree_5_list3_text:"2. 影像检索与浏览：支持通过多种方式进行影像检索，提供灵活、快速的查询结果。同时，用户可以在线浏览、打印和共享影像文件，提高档案的利用效率。",
			titleThree_5_list4_text:"3. 影像质量控制：产品支持对图像清晰度、亮度、对比度等参数的调整和优化，确保影像质量达到要求，满足企业的使用需求。",
			ourStrengths_th5_list1:"全球120+摄影记者，提供专业的拍摄服务",
			ourStrengths_th5_list2:"国际化创意策划团队提供优质的脚本输出及现场拍摄服务",
			ourStrengths_th5_list3:"具备档案学和影像学管理行业经验",
			serviceProcess_th5_list1: "沟通客户需求，安排合适的摄影师或摄影记者",
			serviceProcess_th5_list2: "提前沟通时间，配合客户拍摄",
			serviceProcess_th5_list3: "定期视频更新和存档",
			serviceProcess_th5_list4: "定期与客户沟通拍摄意见",
			serviceProcess_th5_list5: "定期更新客户影像档案系统",


			titleThree_6:'全案活动策划',

            titleFore: '软文发布',
			titleFore_list1_text: '译联社提供的软文发布服务，为全球客户提供一站式全方位营销解决方案。我们的软文发布服务涵盖了从市场调研、主题策划、内容撰写到媒体发布的整个过程。我们的专业团队会深入研究客户需求和市场环境，结合目标受众的喜好与需求，精心策划软文的主题和角度。同时，我们拥有经验丰富的软文撰写人员，能够创作出既具有广告性质又具备阅读价值的软文内容，确保文章能够吸引读者的注意力并传递出明确的信息。在软文发布方面，译联社与众多主流媒体、行业网站、博客平台、论坛和社交媒体等建立了广泛的合作关系，能够为客户提供多样化的发布渠道。我们会根据客户的需求和预算，制定合适的发布策略，确保软文能够覆盖到目标受众，并取得良好的传播效果。在发布后，第一时间，我们会为客户提供效果跟踪与反馈。我们会定期收集和分析软文发布后的数据，包括阅读量、点击量、转化率等指标，为客户提供详细的效果报告，帮助客户更好地了解软文营销的效果，并根据实际情况进行调整和优化。',
			serviceProcess_titleFore_list1:"匹配专属媒介顾问，与客户沟通，获取稿件",
			serviceProcess_titleFore_list2:"为客户匹配合适的媒体传播方案",
			serviceProcess_titleFore_list3:"客户确认，提交订单",
			serviceProcess_titleFore_list4:"审核出稿",
			serviceProcess_titleFore_list5:"提供宣发列表及免费舆情监测",
			serviceProcess_titleFore_list6:"客户评价",
			ourStrengths_titleFore_list1:"更快速",
			ourStrengths_titleFore_list2:"在分秒之间把信息迅速精准地投放到指定网络媒体，让信息以最快速的效率传播。",
			ourStrengths_titleFore_list3:"更权威",
			ourStrengths_titleFore_list4:"新闻形式推广，迅速、低成本地提高企业，产品的形象，树立企业正面形象",
			ourStrengths_titleFore_list5:"更易搜索",
			ourStrengths_titleFore_list6:"第一时间出现在百度、360搜索等搜索引擎的新闻搜索结果中，并使其排名靠前",
			ourStrengths_titleFore_list7:"覆盖广",
			ourStrengths_titleFore_list8:"采用新闻传播模式进行推广可以产生频繁的新闻曝光率。另外，大量地域媒体，可以根据您的地域需求，发布辐射到各行业的精准媒体上",


            titleFive: '海外传播',
            titleFive_1:'公关策划',
			titleFive_1_list1_text: '译联社针对客户传播目的和需求，通过扇形传播策略，结合不同国家的媒体规则、受众习惯等，为客户策划和定制海外传播方案',
			serviceProcess_tf1_list1:"确认海外传播需求和目标",
			serviceProcess_tf1_list2:"分析客户企业海外背景现状及背景",
			serviceProcess_tf1_list3:"制定针对性的传播方案",
			serviceProcess_tf1_list4:"项目执行",
			serviceProcess_tf1_list5:"监测总结",


            titleFive_2:'红人传播',
			tf2_title_text1:"海外红人服务",
			tf2_title_text2:"译联社为客户提供优质的全球KOL 解决方案 ，助力品牌方一站式邀约行业相关意见领袖。通过这些红人在社交媒体平台上的内容创作和分享，来推广品牌、产品或服务的一种营销方式。这些红人可能是社交媒体上的名人、博主、意见领袖或行业专家，他们通过海外主流的社交媒体平台（如YouTube、Instagram、TikTok等）与粉丝互动，对粉丝的消费决策产生重要影响，从而精准触达目标受众，以此提高品牌知名度",
			tf2_b_t1:"众多的KOL资源",
			tf2_b_text1:"Facebook Instagram Tiktok Youtube Twitter等平台",
			tf2_b_t2:"丰富的KOL类型",
			tf2_b_text2:"科技、时尚生活、户外旅行等KOL合作",
			tf2_b_t3:"广泛的覆盖地区",
			tf2_b_text3:"欧美、南非、中东、阿联酋、东南亚、亚太覆盖",
			tf2_hz_title_text1:"KOL如何赋能企业",
			tf2_hz_title_text2:"海外社交媒体红人亲自参与客户举办之活动，并提供现场社交媒体同步传播",
			tf2_hz_text1:"出席品牌活动",
			tf2_hz_text2:"社媒矩阵账号联动",
			tf2_hz_text3:"产品种草",
			tf2_hz_text4:"品牌互动",
			
			tf2_service_text1:"了解客户行业，预算及需求",
			tf2_service_text2:"与客户进行沟通，提供定制化方案",
			tf2_service_text3:"针对客户需求，执行与落地方案",
			tf2_service_text4:"为客户提供结案报告",
			
			
			
			


			titleFive_3:'海外发稿',
			titleFive_3_list1_text:"译联社拥有全球优质的6W+媒介资源，合作专注于全球媒介传播与品牌战略咨询，与全球顶级媒体及通讯社达成战略合作，包括美国美联社、法国法新社、英国路透社、日本时事社、韩国韩联社、美国彭博社、意大利安莎社、土耳其阿纳多卢通讯社、西班牙伊比利亚新闻社等数百家通讯社，拥有自有媒体站，全球虚拟记者网络，媒体分发传播可覆盖全球五大洲200多个国家，同步投放200多种语言，覆盖300多个国家和地区，我们可以根据企业的需求和预算，选择合适的发布渠道，确保稿件能够在目标市场获得广泛的传播和关注。",
			ourStrengths_tf3_list1:"独家记者资源和一手海外6W+媒介资源",
			ourStrengths_tf3_list2:"专业服务，为全球客户提供最优的传播路径和策略",
			service_tf_list1:"确认海外传播需求和目标",
			service_tf_list2:"分析客户企业海外背景现状及背景",
			service_tf_list3:"制定针对性的传播方案",
			service_tf_list4:"项目执行",
			service_tf_list5:"监测总结",
			/* serviceProcess_tf3_list1:"确认海外传播需求和目标", */



            titleSix: '记者邀约',
			titleSix_list1_text:"译联社提供海外记者邀约服务，这项服务旨在帮助需要与国际媒体进行接触和合作的企业、机构或个人，搭建起与海外记者沟通的桥梁,帮助您的公关部及新闻中心更加便捷地邀请海外记者参与活动、采访报道或进行其他形式的合作。预定该服务后，您可以指定国别和语种，对接一位媒体顾问，为您提供定制化一站式的海外记者邀约方案。根据您的需求和目标，筛选合适的海外记者，并协助安排采访时间、地点和具体事项。同时，译联社还提供翻译和沟通支持，确保双方在交流过程中能够顺畅沟通，助力商业发展。",
			ourStrengths_titleSix_list1:"200+全球记者，200+语言，6W+媒介资源",
			ourStrengths_titleSix_list2:"专业服务，为全球客户提供最优的传播路径和策略",
			serviceProcess_titleSix_list1:"客户确定海外传播需求",
			serviceProcess_titleSix_list2:"根据客户需求指定发布会传播方案",
			serviceProcess_titleSix_list3:"客户提供相关英文资料",
			serviceProcess_titleSix_list4:"素材加工",
			serviceProcess_titleSix_list5:"媒体公关",
			serviceProcess_titleSix_list6:"现场执行",
			serviceProcess_titleSix_list7:"监测总结",


            titleSeven: '舆情监测',

            titleEight: '产品榜单',
			titleEight_list1_text:"译联社作为全球性的翻译与传播机构，致力于为客户提供全方位的榜单服务。其榜单服务不仅涵盖了多个领域和行业，还注重数据的准确性和时效性，旨在帮助客户及时了解市场动态和竞争态势。",
			titleEight_list2_text:"译联社可针对全球客户定向分析，为客户提供行业榜单，产品榜单和媒体榜单。",
			ourStrengths_titleEight_list1:"2000+分析师，全球行业认可",
			ourStrengths_titleEight_list2:"专业服务，为全球客户提供最优商业界",
			serviceProcess_titleEight_list1:"沟通客户需求",
			serviceProcess_titleEight_list2:"明确榜单类型",
			serviceProcess_titleEight_list3:"提供榜单方案",
			serviceProcess_titleEight_list4:"执行榜单策略与方案",
			serviceProcess_titleEight_list5:"榜单领奖",


            titleNine: '整合营销',
            // 关于我们
            SyWepWire:'关于译联社',
            WepWire: '上海译连社科技有限公司成立于2019年，总部位于上海，是全球领先的公共关系和营销传播机构。在全球，我们拥有150个国家记者网络，合作记者数量200余名。我们合作伙伴有国际知名通讯社、全球优质媒体与红人针对媒体分发传播和品牌营销服务，我们可覆盖全球五大洲150多个国家。全球优质媒介资源达6万家以上，同步投放200多种语言。译联社建立起世界通往中国的经济窗口，构筑了中国声音和中国品牌对外的媒介枢纽。',
			qywh:"企业文化",
            qyln:"企业理念",
            qylnWz:"促进社会繁荣，增进人民幸福",
            qyyj:"企业愿景",
            qyyjWz:"助力人才多元化，助力商业全球化",
            qykh:"企业口号",
            qykhWz:"传播中国声音，链接中外商业，沟通记录时代",

            medium:"五大洲的国家",
            yy:"国家地方语言",
            hyzj:"国家记者网络",
            yzmt:"全球优质媒体资源",

            // 真实故事
            TwoNy:'真实记录与创作是译联社内容创作团队为全球客户提供的当下在线即时文字和影像服务，我们提供1V1 的全球优质媒体人，与您在线联线，精准分析您的内容生产需求，为您提供优质高效的内容生产服务。',
            // 词条创作
            ThreeOne_1:"译联社内容生产中心，秉持着全球化的视野和专业化的服务态度，致力于为全球客户提供卓越的词条创作服务。我们拥有丰富的创作经验和深厚的行业背景，能够为客户提供起名、各类广告语、宣传语制作等创意类词条输出服务。",
            EntryCreation_2:'译联社内容生产中心以全球化视野和专业化服务态度，为全球客户提供词条创作服务，包括但不仅限于起名、各类广告语、宣传语制作等创意类词条输出服务。',
            DraftWriting_1:'通稿撰写',
            DraftWriting_2:'译联社内容生产中心为全球客户提供优质的稿件创作服务，客户可以任意选定语种，我司提供免费1对1 免费无时差沟通服务，为您定制您的稿件。',
            PhotographyServices_1:'照片拍摄',
            PhotographyServices_2:'译联社全球100+优质摄影记者，5000+摄影师，1000+云端图像处理师，能够根据客户需求为客户提供产品、会议、空间、内容的拍摄服务，免费提供剪辑服务。',


            // 软文发布
            rwfbfw:"软文发布服务",
            contentOneWa:"一站式服务",
            contentOne: "译联社提供的软文发布服务，为全球客户提供一站式全方位营销解决方案。我们的软文发布服务涵盖了从市场调研、主题策划、内容撰写到媒体发布的整个过程。我们的专业团队会深入研究客户需求和市场环境，结合目标受众的喜好与需求，精心策划软文的主题和角度。同时，我们拥有经验丰富的软文撰写人员，能够创作出既具有广告性质又具备阅读价值的软文内容，确保文章能够吸引读者的注意力并传递出明确的信息。",
            contentTwoWz: "定制服务",
            contentTwo: "译联社与众多主流媒体、行业网站、博客平台、论坛和社交媒体等建立了广泛的合作关系，能够为客户提供多样化的发布渠道。我们会根据客户的需求和预算，制定合适的发布策略，确保软文能够覆盖到目标受众，并取得良好的传播效果。在发布后，第一时间，我们会为客户提供效果跟踪与反馈。我们会定期收集和分析软文发布后的数据，包括阅读量、点击量、转化率等指标，为客户提供详细的效果报告，帮助客户更好地了解软文营销的效果，并根据实际情况进行调整和优化。",
            video_1:'视频拍摄服务',
            video_2:'译联社全球摄影记者和优质摄影师团队专注于商业视频拍摄制作，为客户提供商业视频拍摄制作内容制作，我们提供1对1的免费顾问沟通您的需求，为您提供专业的视频策略制作服务，   用娴熟精湛的专业技术，探索、思考并发掘光与影的艺术，从创意策划、化妆造型、演员配音到编剧导演、摄像摄影、后期制作、三维包装的高素质、高效率、高水准的专业化队伍。公司拥有先进的直播拍摄和影像后期特效设备，包括SGI图形工作站，非线性编辑设备；拥有全高清专业级的摄录设备，索尼970、松下AJ-910、索尼HDWF900R、SONY EX1R 、Z5C 、Z7C、佳能5D Mark II、7D、60D等多种型号的高清标清摄影、摄像机。高清拍摄中我们会配备摇臂，轨道，大炮，摄像灯光等专业的辅助设备，协助客户发现与创造品牌的价值。',
            Production_1:'影响档案制作与管理',
            Production_2:'译联社为政企客户提供优质的影像档案制作与管理服务，，将企业的纸质档案和其他形式的原始资料转化为电子影像文件，并进行高效存储、管理、检索和共享。',
            Production_2_1:'1. 影像存储与管理：通过数字化处理，将企业的纸质档案、照片、视频等原始资料转换为电子影像文件，并进行分类、归档和存储。产品提供多种存储方式，如本地磁盘存储、服务器存储和云存储等，确保数据的安全可靠。',
            Production_2_2:'2. 影像检索与浏览：支持通过关键字、日期、文档类型等多种方式进行影像检索，提供灵活、快速的查询结果。同时，用户可以在线浏览、打印和共享影像文件，提高档案的利用效率。',
            Production_2_3:'3. 影像质量控制：产品支持对图像清晰度、亮度、对比度等参数的调整和优化，确保影像质量达到要求，满足企业的使用需求。',

            // 海外传播
            OverseasPublishingWz:"海外发稿服务",
            OverseasPublishing:"译联社合作10W+ 中国国内知名媒体，如新华网，人民网，凤凰网等，收录好，初稿快，时效长。我们会根据客户的需求和预算，制定合适的发布策略，确保软文能够覆盖到目标受众，并取得良好的传播效果，在发布后，第一时间为客户提供详细的效果报告，帮助客户更好地了解软文营销的效果，并根据实际情况进行调整和优化",
            journalistInvitationWz:"记者邀约服务",
            journalistInvitation:"译联社提供海外记者邀约服务，这项服务旨在帮助需要与国际媒体进行接触和合作的企业、机构或个人，搭建起与海外记者沟通的桥梁,帮助您的公关部及新闻中心更加便捷地邀请海外记者参与活动、采访报道或进行其他形式的合作。预定该服务后，您可以指定国别和语种，对接一位媒体顾问，为您提供定制化一站式的海外记者邀约方案。根据您的需求和目标，筛选合适的海外记者，并协助安排采访时间、地点和具体事项。同时，译联社还提供翻译和沟通支持，确保双方在交流过程中能够顺畅沟通，避免误解和障碍。",

            // 业务服务
            hdch_1:'活动策划服务',
            hdch_2:'译联社创意团队为全球客户提供无国界，有创意，多创新全案活动策划及线下执行。我们拥有国内外的广告物料设计搭建团队，可为客户提供发布会、年会、会议论坛、赛事、周年庆、酒会、颁奖典礼、开业奠基、地产活动、文化活动、快闪活动等。',
            yqjc_1:'舆情监测服务',
            yqjc_2_1:'政企舆情大数据服务平台',
            yqjc_2_2:'全网监测 洞察舆情',
            yqjc_2_3:'官方数据支持',
            yqjc_2_4:'全网信息 7*24h实时监测',
            yqjc_2_5:'极速预警 精准识别风险',
            yqjc_2_6:'智能分析 洞察舆情走向',
            zhyx_1:'整合营销服务',
            zhyx_2:'译联社致力于为全球客户提供优质的整合营销服务。整合营销服务涵盖多个方面，包括市场调研、品牌定位、内容创作、多渠道推广等。我们凭借丰富的行业经验和专业的团队，能够为客户量身定制最适合的营销方案，确保信息准确传达，品牌形象深入人心。在翻译与本地化方面，我们拥有专业的翻译团队和技术支持，能够处理各种语言和文化背景的翻译需求。我们注重翻译的准确性和文化适应性，确保客户的信息在不同国家和地区都能得到准确传达，从而提升品牌影响力和市场竞争力。',
            cpbd_1:'产品榜单服务',
            cpbd_2:'译联社作为全球性的翻译与传播机构，致力于为客户提供全方位的榜单服务。其榜单服务不仅涵盖了多个领域和行业，还注重数据的准确性和时效性，旨在帮助客户及时了解市场动态和竞争态势。译联社可针对全球客户定向分析，为客户提供行业榜单，产品榜单和媒体榜单\n',
            cpbd_2_1:'1.专业性强：译联社拥有专业的翻译和传播团队，对各个行业和领域都有深入的了解和研究。因此，我们的榜单服务能够针对不同客户的需求，并提供具有专业性和针对性的信息和数据。',
            cpbd_2_2:'2.数据准确：译联社注重数据的收集和整理，通过多种渠道获取权威的数据来源，并进行严格的核实和筛选。同时，译联社还采用先进的数据分析工具和方法，确保榜单数据的准确性和可靠性。',
            cpbd_2_3:'3.实时更新：译联社的榜单服务能够实时跟踪市场动态和行业发展，及时更新榜单数据。通过译联社的榜单服务，客户可以更好地了解市场趋势、行业发展和竞争对手的情况，为企业的决策和规划提供有力的支持和参考。同时，译联社的榜单服务还能够提升客户的品牌形象和市场影响力，增强客户在行业内的竞争力和话语权。\n',

            // 联系我们
            contact: '联系我们',
            yx1:'联系邮箱',
            yx2:'投稿邮箱',
            Address_1:'地 址',
            Address_2:'上海市静安区灵石路718号大宁中心广场B1南楼413-7',
            number:'联系电话',
            WxGzh:'微信公众号',
            eup1:'译联社资讯',
            eup2:'译联说传播',
            // 优势
            OurAdvantages_1:"7天，24h在线，为您提供无时差，无语言交流高效服务。",
            OurAdvantages_2:"200+全球知名通讯社媒体人，为您提供精准符合地方国情与法规的媒介传播服务。",
            OurAdvantages_3:"2000+行业专家，为您的商业开拓和发展提供高效建议。",
            OurAdvantages_4:"6W+优质媒介资源，一站式传播与分发您的稿件。",
            OurAdvantages_5:"30+传播载体， 1000+创意案例，优质策略和无限创意。",
            // 企业历程
            Rc_1:"上海远洲商务咨询有限公司成立，我司为全球客户提供优质的笔译和口译服务",
            Rc_2:"全球语言人才汇聚，中东和欧洲翻译事业部独立运营",
            Rc_3:" 公司业务转型服务贸易：为全球采购商提供优质的代采买和代参展服务，积累了良好的客户基础和口碑效应",
            Rc_4:"公司业务创新：非盈利机构背书，全球记者，英贤聚齐，共研传播新时代",
            Rc_5:"同年，公司收到全球客户嘉奖，上级部委表扬，在传播渠道和内容制作方面树立行业新标杆",
            Rc_6:"译连社成立，申请商标完成",
            EnterpriseDevelopment:"企业发展",

            // 这里是用来测试填充内容的文本
            test_text:"这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。这里是测试内容，用来测试填充文本显示效果。",
			bc1_t:"广汽GAC出海，打响世界",
			bc1_b:"推动GS4全球销售、提升GAC全球品牌形象",

			bc2_t:"美的智能绿色能源管理解决方案MHELIOS发布，推动更智能、更绿色的生活方式",
			bc2_b:"泰国发布会记者邀约、现场记者专访、法新社媒体发布、雅虎财经、Bezinga等垂直财经门户通投",


			bc3_t:"锦绣山河美国洛杉矶巡回展",
			bc3_b:"策划中美两地的锦绣山河相关主题的摄影展、",

			bc4_t:"赞比亚总统出席“万村通”项目启动模式",
			bc4_b:"中非合作项目“万村通”，旨在让非洲国家1万个村庄收看到卫星数字电视。经过多年不懈努力，这个规模宏大的项目正在接近实现既定目标，为千万非洲民众打开了解外界信息的窗口。",

			bc5_t:"海外红人客户案例",
			bc5_b:"海外KOL（Key Opinion Leader，关键意见领袖）在品牌推广和客户营销中扮演着越来越重要的角色",

			bc6_t:"欧洲记者采风团：中国经济发展强国之路",
			bc6_b:"随着中国经济的飞速发展，越来越多的国际目光聚焦在这片古老的土地上。近期......",
			AnLi6_h3_1:"背景介绍",
			AnLi6_h3_1_1:"随着中国经济的飞速发展，越来越多的国际目光聚焦在这片古老的土地上。近期，一支由欧洲知名媒体组成的记者采风团来到中国，深入探访中国经济发展的奥秘，感受中国经济发展的强大动力。",
			AnLi6_h3_2:"采风过程",
			AnLi6_h3_2_1_1:"高新区探访：",
			AnLi6_h3_2_1_2:"记者团首先来到了中国的高新区，这里是中国新经济发展的独特而有效的路径。在高新区，记者们亲眼目睹了新能源、人工智能、生物制造等前沿技术的研发和应用推广，感受到了中国科技创新的蓬勃活力。",
			AnLi6_h3_2_2_1:"企业考察：",
			AnLi6_h3_2_2_2:"随后，记者团深入企业，与企业家、技术专家进行深入交流。通过实地参观和采访，记者们了解到中国企业在转型升级、创新驱动、绿色发展等方面取得的显著成果。同时，他们也感受到了中国企业家的创新精神、拼搏精神和担当精神。",
			AnLi6_h3_2_3_1:"政策解读：",
			AnLi6_h3_2_3_2:"在采风过程中，记者团还采访了多位中国政府官员和专家学者，深入了解了中国经济发展的政策环境和战略部署。他们表示，中国政府通过加强财政、货币、就业、产业、区域、科技、环保等政策协调配合，确保了经济稳定增长和可持续发展。",
			AnLi6_h3_2_4_1:"城乡融合：",
			AnLi6_h3_2_4_2:"记者团还探访了中国城乡融合发展的典型地区。在这里，他们看到了城乡基础设施互联互通、公共服务共建共享、产业协同发展等成果，感受到了城乡融合带来的巨大变化。",
			AnLi6_h3_3:"感受与收获",
			AnLi6_h3_3_1_1:"经济发展迅速：",
			AnLi6_h3_3_1_2:"记者团深刻感受到中国经济发展的迅猛势头。无论是从规模、速度还是质量上，中国经济都呈现出强劲的发展动力。",
			AnLi6_h3_3_2_1:"绿色发展理念：",
			AnLi6_h3_3_2_2:"中国经济发展的一个重要特点是创新驱动。政府和企业都高度重视科技创新和人才培养，通过创新来推动经济转型升级和高质量发展。",
			AnLi6_h3_3_3_1:"改革开放成果显著：",
			AnLi6_h3_3_3_2:"中国政府坚持绿色发展理念，推动经济与环境协调发展。记者们看到，在中国经济发展的过程中，生态环境得到了有效保护和改善。",
			AnLi6_h3_3_4_1:"城乡融合：",
			AnLi6_h3_3_4_2:"记者团认为，中国经济发展的成就离不开改革开放的伟大实践。改革开放为中国经济发展注入了强大动力，使中国成为世界的工厂和市场的重要参与者。",
			AnLi6_h3_4:"总结与展望",
			AnLi6_h3_4_1:"通过这次采风活动，欧洲记者团深刻感受到了中国经济发展的强大动力和独特魅力。他们表示，中国经济发展的成功经验值得借鉴和学习。未来，他们将继续关注中国经济发展的动态和趋势，为推动中欧经贸合作和文化交流做出积极贡献。同时，他们也期待中国能够在全球经济中发挥更加重要的作用，为世界经济发展注入新的动力。",
		}
    }
};
