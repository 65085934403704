import Vue from "vue";
import "./plugins/axios";
import store , { i18n } from "./store";

import $ from "jquery";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import  animated from 'animate.css'

import App from "./App.vue";
import router from "./router";


Vue.use($)
Vue.use(animated)
Vue.prototype.$i18n = i18n;
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
