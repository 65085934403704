<template>
	<div>
		<nav2></nav2>
		<banner2 :bannerImg="Img1" :one="t('title')"></banner2>
		<div class="fixed-width-container container row" style="margin-top: 50px;">
			<div class="col-12">
				<h1 style="text-align: center;" class="display-6">{{t("title")}}</h1>
			</div>
			<div style="margin-top: 35px" class="row">
				<div>

					<p class="">
						<span class="text-bold"> {{t("h1_t1")}}</span>{{t("h1_t2")}}
					</p>
					
					<p class="">
						<span class="text-bold"> {{t("h2_t1")}}</span>{{t("h2_t2")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/3-1.png"  width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/3-2.png"  width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/3-3.png"  width="800" />
					</p>






				</div>
			</div>
		</div>
		<div style="padding: 25px;"></div>
		<footer2></footer2>
	</div>
</template>

<script setup>
	import footer2 from "../../components/footer.vue";
	import banner2 from "../../components/banner.vue";
	import nav2 from "../../components/nav.vue";

	export default {
		name: "index",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
		},
		data() {
			return {
				Img1: require("../../assets/img/keji/矿山banner1.png"),
				content: {
					title: {
						zh: "锦绣山河美国洛杉矶巡回展",
						en: "Fairview USA Touring Exhibition in Los Angeles"
					},
					h1_t1: {
						zh: "客户需求：",
						en: "Customer Needs:",
					},
					h1_t2: {
						zh: "策划中美两地的锦绣山河相关主题的摄影展",
						en: "Curating photography exhibitions on the theme of \"Beautiful Mountains and Rivers\" in China and the United States.",
					},
					
					
					h2_t1: {
						zh: "落地策略：",
						en: "Landing Strategy:",
					},
					h2_t2: {
						zh: "提供双语创意策划团队，做好活动前、中、后的沟通、落地及执行",
						en: "Provide bilingual creative planning team to do the communication, landing and execution before, during and after the event",
					},


				},
				language: "en",

			};
		},
		created() {
			this.language = this.$i18n.getLocale();
		},
		methods: {
			t(res) {
				return this.content[res][this.language];
			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.fixed-width-container {
		width: 800px !important;
		/* 设置固定宽度 */
		max-width: 100%;
		/* 确保在小屏幕上宽度占满容器 */
		margin: 0 auto;
		/* 水平居中 */
	}

	p {
		margin-top: 10px;
	}

	p.text-bold {
		margin-top: 25px;
	}
</style>
