<template>
	<!-- star -->
	<div>
		<div>
			<!--      导航栏-->
			<nav2></nav2>
			<!--      轮播图-->
			<div id="carouselExample" class="carousel slide">
				<div class="carousel-inner">
					<div class="carousel-item active">
						<video class="d-block w-100" :autoplay="true" playsinline="" muted="" loop="">
							<source src="../assets/images/0-EupWire/mp4.mp4" type="video/mp4">
						</video>
						<div class="carousel-caption">
							<h1 class="display-1" style="font-weight:900;padding-bottom: 10px;">EUPWire</h1>
							<p class="h6">连线全球，助力中国品牌出海</p>
							<p class="h6">Connect the World ; Spreading Chinese Voice !</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="homepage_wrap">
			<!-- 关于企业 -->
			<div class="AboutEnterprises">
				<div class="AboutEnterprises_cont">
					<!-- top -->
					<div class="AboutEnterprises_cont_top">
						<!-- top_left -->
						<div class="AboutEnterprises_cont_top_left">
							<h1>{{$i18n.t('SyWepWire')}}</h1>
							<p :style="{lineHeight: ($i18n.getLocale() === 'en' ? '20px' : '40px')}">
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$i18n.t('WepWire')}}
							</p>
							<router-link to="/titleTwo">
								<div class="AboutEnterprises_cont_top_left_btn">
									<span v-if="$i18n.getLocale() == 'zh'">了解更多</span>
									<span v-else >Learn more</span>
									<img src="../assets/images/0-EupWire/1-2.png" alt="" />
								</div>
							</router-link>
						</div>
						<!-- top_right -->
						<div class="AboutEnterprises_cont_top_right">
							<img src="../assets/images/0-EupWire/1-1.png" width="500" height="500" alt="" />
							<!-- <div></div> -->
						</div>
					</div>
					<!-- bottom -->
					<div class="AboutEnterprises_cont_bottom">
						<div class="item">
							<p style="text-align: center">
								<span class="item-1">150</span>
								<span class="item-2">+</span>
							</p>
							<p class="item-3">{{$i18n.t('medium')}}</p>
						</div>
						<div class="item">
							<p style="text-align: center">
								<span class="item-1">200</span>
								<span class="item-2">+</span>
							</p>
							<p class="item-3">{{$i18n.t('yy')}}</p>
						</div>
						<div class="item">
							<p style="text-align: center">
								<span class="item-1">200</span>
								<span class="item-2">+</span>
							</p>
							<p class="item-3">{{$i18n.t('hyzj')}}</p>
						</div>
						<div class="item">
							<p style="text-align: center">
								<span class="item-1">6W</span>
								<span class="item-2">+</span>
							</p>
							<p class="item-3">{{$i18n.t('yzmt')}}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 集团产业 -->
			<!-- footer -->
			<footer2></footer2>

			<!-- end -->
		</div>
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import nav2 from "../components/nav.vue";
	export default {
		name: "index",
		components: {
			nav2,
			footer2,
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
		},
	};
</script>
<style>
	/*=========================Reset_start==========================*/
	* {
		margin: 0;
		padding: 0;
	}

	/*视频*/
	.videoContainer {
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -100;
	}

	.videoContainer:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(25, 29, 34, .65);
		background-size: cover;
	}

	/*去内外边距*/
	html,
	body {
		font-family: "微软雅黑", "宋体", Arail, Tabhoma;
		text-align: left;
	}

	/*字体 文本居左*/
	ul {
		list-style: none;
	}

	/*去ul li 小点*/
	img {
		border: 0 none;
	}

	/*img IE6 兼容问题*/
	input,
	select,
	textarea {
		outline: 0;
	}

	/*去除点击蓝框*/
	textarea {
		resize: none;
		overflow: auto;
	}

	/*锁定多行文本框大小 与文字超出出现滚动条*/
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/*表格边框合并 边框间距0*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}

	/*特殊文字粗细 样式显示正常*/
	a {
		text-decoration: none;
	}

	/*a标签去下划线*/
	.clearfix:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	/*清浮动*/
	a {
		color: #000000;
	}

	/*==========================Reset_End===========================*/

	.carousel-caption {
		top: 50%;
		/* 根据需要调整 */
		bottom: auto;
		transform: translateY(-50%);
	}

	.homepage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	.banner_nav {
		//width: 100vw;
		height: 800px;
		position: relative;
	}

	.banner_nav_bg img {
		width: 100%;
		/* 文本居顶部对齐 */
		vertical-align: top;
	}

	/* 导航部分 */

	.navWrap {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		/*  */
		margin: 0px auto;
	}

	.navWrap_center {
		width: 1440px;
		height: 101px;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 51px;
		/*  */
		display: flex;
		justify-content: space-between;
	}

	.navWrap_center_img {
		width: 200px;
		height: 80px;
		margin-left: 100px;
		margin-right: 66px;
		margin-top: 5px;
	}

	.navWrap_center_ul {
		/* border:1px solid red; */
		margin-right: 75px;
	}

	.navWrap_center_ul li {
		display: inline-block;
		height: 98px;
		line-height: 98px;
		/* border-bottom: 4px solid blue; */
		margin-left: 50px;
		padding: 0 5px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		/* line-height: 22px; */
	}

	.navWrap_center_ul li:hover {
		cursor: pointer;
		color: rgba(35, 51, 127, 1);
		border-bottom: 2px solid rgba(35, 51, 127, 1);
	}

	/* title */
	.banner_title_wrap {
		width: 100%;
		position: absolute;
		bottom: 290px;
		/* border: 1px solid yellow; */
	}

	.banner_title_center {
		/* border: 1px solid white; */
		text-align: center;
		width: 1200px;
		margin: 0 auto;
		font-size: 90px;
		font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
		font-weight: bold;
		color: #ffffff;
		line-height: 124px;
		letter-spacing: 1px;
	}

	.banner_title_center div {
		padding-top: 14px;
		font-size: 20px;
		font-family: Helvetica;
		line-height: 24px;
		letter-spacing: 4px;
	}

	.banner_title_center div span {
		margin: 0 18px;
	}

	/* news */
	.news {
		width: 100vw;
		height: 801px;
		background: #f8f8f8;
	}

	.news_cont {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.news_cont_left {
		margin-top: 89px;
	}

	.news_cont_left_img img {
		width: 588px;
		height: 353px;
		margin-top: 41px;
	}

	.news_cont_left_text {
		padding-top: 27px;
		padding-left: 40px;
		width: 548px;
		height: 150px;
		background-color: #ffffff;
	}

	.news_cont_left_text h1 {
		font-size: 18px;
		font-weight: 500;
		color: #23337f;
	}

	.news_cont_left_text p {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8f8f8f;
		margin-top: 10px;
	}

	.news_cont_right {
		width: 588px;
		margin-top: 103px;
	}

	.news_cont_right_ul ul {
		font-size: 16px;
		font-weight: 400;
		color: #b3b3b3;
		line-height: 22px;
		float: right;
		display: flex;
	}

	.news_cont_right_ul ul a {
		font-size: 16px;
		font-weight: 400;
		color: #b3b3b3;
		line-height: 22px;
		float: right;
		display: flex;
	}

	.news_cont_right_ul ul li:hover {
		color: #23337f;
	}

	.news_cont_right_ul ul span {
		margin: 0 23px 0 24px;
	}

	.news_cont_right_text {
		width: 588px;
		height: 535px;
		background: #ffffff;
		margin-top: 68px;
	}

	.news_cont_right_text_item {
		padding: 36px;
	}

	.news_cont_right_text_item h1 {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 25px;
	}

	.news_cont_right_text_item p {
		margin-top: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8f8f8f;
		line-height: 20px;
	}

	.news_cont_right_text_hr {
		/* float: right; */
		width: 508px;

		border-bottom: 1px solid #d7d7d7;
	}

	.news_cont_right_text_hr:nth-of-type(3) {
		/* float: right; */
		width: 508px;

		border-bottom: none;
	}

	.news_cont_right_text_item2 {
		padding: 24px 40px;
	}

	.news_cont_right_text_item2 h1 {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 25px;
	}

	.news_cont_right_text_item2 p {
		margin-top: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8f8f8f;
		line-height: 20px;
	}

	/* 关于企业 */
	.AboutEnterprises {
		width: 100vw;

		background-image: url("../assets/images/0-EupWire/1-1-bg.png");
		background-size: 100% 100%;
		background-attachment: fixed;
	}

	.AboutEnterprises_cont {
		width: 1200px;
		margin: 0 auto;
		height: 100%;
	}

	.AboutEnterprises_cont_top {
		display: flex;
		justify-content: space-between;
	}

	.AboutEnterprises_cont_top_left {
		margin-top: 143px;
		width: 600px;
	}

	.AboutEnterprises_cont_top_left>h1 {
		font-size: 36px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #ffffff;
		line-height: 50px;
	}

	.AboutEnterprises_cont_top_left p {
		margin-top: 36px;

		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 40px;
	}

	.AboutEnterprises_cont_top_left_btn {
		width: 160px;
		height: 40px;
		border: 1px solid rgba(255, 255, 255, 0.8);
		margin-top: 40px;
		display: flex;
		align-items: center;
	}

	.AboutEnterprises_cont_top_left_btn img {
		width: 24px;
		height: 24px;

		margin-left: 8px;
	}

	.AboutEnterprises_cont_top_left_btn span {
		margin-left: 32px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 22px;
	}

	.AboutEnterprises_cont_top_right {
		position: relative;
		width: 487px;
		height: 517px;
		margin-top: 115px;
	}

	.AboutEnterprises_cont_top_right img {
		position: absolute;
		z-index: 1;
	}

	.AboutEnterprises_cont_top_right Div {
		width: 317px;
		height: 408px;
		background: #3491e5;
		position: absolute;
		top: 64px;
		left: -33px;
	}

	.AboutEnterprises_cont_bottom {
		display: flex;

		margin-top: 80px;
		padding-bottom: 40px;
	}

	.AboutEnterprises_cont_bottom_item {
		margin-right: 70px;
		position: relative;
		width: 247px;
		border-right: 1px solid #ffffff;
		display: flex;
	}

	.AboutEnterprises_cont_bottom_item3 {
		margin-right: 70px;
		position: relative;
		width: 323px;
		border-right: 1px solid #ffffff;
		display: flex;
	}

	.AboutEnterprises_cont_bottom_item3 p {
		width: 86px;
		height: 28px;
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #b30588;
		line-height: 28px;
	}

	.AboutEnterprises_cont_bottom_item3 h1 {
		font-size: 60px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		line-height: 84px;
	}

	.AboutEnterprises_cont_bottom_item3 span {
		font-size: 17px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #692020;
		line-height: 24px;
	}

	.AboutEnterprises_cont_bottom_item4 {
		position: absolute;
		left: 161px;
		bottom: 15px;
	}

	.AboutEnterprises_cont_bottom_item h1 {
		font-size: 60px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		line-height: 84px;
	}

	.AboutEnterprises_cont_bottom_item span {
		font-size: 17px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #692020;
		line-height: 24px;
	}

	.AboutEnterprises_cont_bottom_item p {
		width: 86px;
		height: 28px;
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #b30588;
		line-height: 28px;
	}

	.CooperativePartner_cont_center_item img {
		width: 40px;
		height: 40px;
	}

	.CooperativePartner_cont_center_item span {
		line-height: 69px;
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #23337f;
		line-height: 33px;
		margin-left: 14px;
	}

	.CooperativePartner_cont_bottom_logo img {
		width: 227px;
		height: 120px;
		background: #ffffff;
		box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
		border-radius: 9px;
		margin-bottom: 16px;
	}

	.item {
		top: 236px;
		float: left;
		width: 290px;
	}

	.item-1 {
		color: rgb(255, 255, 255);
		font-size: 60px;
	}

	.item-2 {
		color: rgb(255, 255, 255);
		font-size: 20px;
	}

	.item-3 {
		color: rgb(210, 210, 210);
		font-size: 14px;
		text-align: center;
	}
</style>
