<template>
	<div id="app">
		<!-- <daohang> </daohang> -->

		<router-view></router-view>
		<!-- <dibudaohang> </dibudaohang> -->
	</div>
</template>

<script>
	// import daohang from "./components/index/daohang.vue";
	// import dibudaohang from "./components/dibudaohang/dibudaohang";



	export default {
		name: "App",
		components: {
			// $t()
			// daohang,
			// dibudaohang
		},
	};
</script>

<style>
	body {
		margin: 0;
		overflow-x: hidden;

	}

	.flex-align-t {
		display: flex;
		height: 100%;
		align-items: start;
	}

	.flex-align-c {
		display: flex;
		height: 100%;
		align-items: center;
	}

	.flex-align-b {
		display: flex;
		height: 100%;
		align-items: end;
	}

	/* 全局宽度 */
	.w1200 {
		width: 1200px;
	}

	/* 全局标题 */
	.rowTitle {
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
		color: #002459;
		margin-bottom: 20px;
	}

	/* 全局我的优势 */
	.ys-box .ys {
		display: flex;
		justify-content: start;
		width: 1200px;
		flex-wrap: wrap;
	}

	.ys .ys-item {
		width: 300px;
		height: 300px;
		display: flex;
		flex-direction: column;
		cursor: pointer;

	}

	.ys .ys-item.ys-item-col2 {
		width: 600px;
		height: 300px;
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	.ys .ys-item:hover {
		opacity: 0.8;
	}

	.ys .ys-title {
		height: 160px;
		display: flex;
		align-items: end;
		justify-content: center;
		font-size: 100px;
		font-weight: 600;
		line-height: 125px;
		position: relative;
	}

	.ys .ys-title .yl-subTitle {
		position: absolute;
		bottom: -10px;
		font-size: 20px;
		display: block;
		line-height: 20px;
		padding: 15px 20px;
		right: calc(50% - 79px);
		background: #fff;
	}

	.ys .yl-body {
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
		justify-content: center;
	}

	.ys .yl-body .yl-content {
		font-size: 16px;
		line-height: 30px;
		padding-left: 30px;
		padding-right: 30px;
		text-align: center;
	}

	.ys .yl-body .yl-tips {
		color: #696969;
		width: 80%;
		text-align: center;
	}

	.ys-item.ys-item-dark,
	.ys-item.ys-item-dark .yl-subTitle {

		background: #002459;
		color: #fff;
	}

	.ys-item.ys-item-dark .yl-tips {

		color: #eaeaea;
	}

	/* 服务流程 */
	div.lcArr {
		display: flex;
		padding: 120px 0 !important;
		column-gap: 140px;
		position: relative;
	}

	div.lcArr .hengLine {
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #002459;
		top: calc(50% - 1px);
		z-index: 1;
	}

	.lcArr .lcItem {
		position: relative;
	}

	.lcArr .lcItem .lcCircle {
		width: 60px;
		height: 60px;
		text-align: center;
		background-color: #002459;
		border-radius: 50%;
		font-size: 20px;
		color: #fff;
		font-weight: 600;
		position: relative;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lcArr .lcItem .lcTitleTop,
	.lcArr .lcItem .lcTitleBotton {
		/* width: 160px;
  text-align: center;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center; */
		height: 90px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.lcArr .lcItem .lcTitleTop>span,
	.lcArr .lcItem .lcTitleBotton>span {
		width: 160px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
	}

	.lcArr .lcItem .lcLine {
		width: 2px;
		height: 90px;
		margin-bottom: 10px;
		background-color: #0024595e;
		position: relative;
	}

	.lcArr .lcItem .lcTitleTop .lcLine {
		margin-top: 10px;
		margin-bottom: 0;
	}

	.lcArr .lcItem .lcTitleTop>span {
		top: -30px;
	}

	.lcArr .lcItem .lcTitleBotton .lcLine {
		margin-bottom: 10px;
	}

	.lcArr .lcItem .lcTitleBotton>span {
		bottom: -30px;
	}

	.lcArr .lcItem .lcTitleBotton .lcLine::after {
		content: ' ';
		display: block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: #002459;
		position: absolute;
		left: -2px;
		bottom: 0;
	}

	.lcArr .lcItem .lcTitleTop .lcLine::before {
		content: ' ';
		display: block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: #002459;
		position: absolute;
		left: -2px;
		top: 0;
	}

	.lcArr.lcArrPlan .lcCircle {
		background-color: #fff;
		border: 10px solid #002459;
		color: #002459;
	}

	.box {
		background-color: #fff;
	}

	.flow {
		width: 100%;
		background: #fff;
		padding: 20px 0;
	}

	.flow p {
		text-align: center;
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
		color: #002459;
		margin-bottom: 20px;
	}

	.flow .hx-box {
		display: flex;
		justify-content: center;
	}

	.flow .hx {
		border-top: 2px solid #333;
		width: 60px;
		text-align: center;
	}

	.bs {
		background: #fff;
	}

	.ys-box {
		display: flex;
		justify-content: center;
	}

	.ys {
		display: flex;
	}

	.ys img:hover {
		opacity: 0.8;
	}

	.al-box {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.al {
		display: flex;
		width: 1200px;
		justify-content: center;
	}

	.al>div {
		width: 33.33%;
		position: relative;
	}

	.al>div>img {
		width: 100%;
		height: 288px;
	}

	.al>div>div {
		display: none;
	}

	.al>div:hover>div {
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		height: 100%;
		width: 100%;
		background-color: #191010aa;
	}

	.al>div>div>div {
		width: 100%;

		text-align: center;
		position: absolute;
		padding: 5px;
		color: #dedede;
		overflow: hidden;
	}

	.al>div>div>div:nth-of-type(1) {
		display: inline-block;
		bottom: 50%;
		left: 0;
		max-height: 50%;
		font-size: 18px;
		line-height: 30px;
		text-decoration: underline;

	}

	.al>div>div>div:nth-of-type(2) {

		position: absolute;
		top: 50%;
		left: 0;
		font-size: 14px;
		line-height: 30px;
		color: #dedede;

		height: 50%;

	}









	.box {
		display: flex;
		justify-content: center;
		background: #fff;
		padding-bottom: 40px;
	}

	.main {
		width: 1250px;
		padding-top: 60px;
	}

	.display-5 {
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
		color: #002459;
	}

	.info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 50px;
	}

	.info-text {
		padding-left: 53.2px;
		box-sizing: border-box;
		color: #666666;
		font-size: 16px;
		line-height: 27px;
	}

	.info-text p {
		font-size: 16px;
		line-height: 24px;
		color: #666666;
		margin-top: 30px;
	}

	.fwtitle {
		font-size: 30px;
		line-height: 60px;
		color: #333333;
		font-weight: bold;
		margin-top: -10px;
	}

	/* models */
	.titleSlot {
		padding: 20px 0 40px;
	}

	.textTitle {
		box-sizing: border-box;
		color: #666666;
		font-size: 16px;
		line-height: 27px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.textTitle.textTitleCenter {
		justify-content: center;
	}

	.textTitle .textContent {
		margin-top: 30px;
		text-indent: 2em;
	}

	.subTextTitle {
		font-size: 20px;
		font-weight: 600;
		color: #333;
		margin: 15px 0 5px;
		text-align: center;
	}

	.box2 {
		column-gap: 100px;
	}

	.boxLeft {
		padding: 15px;
		border: 2px solid;
		border-image: linear-gradient(to right, #7094d3, #7094d36b) 1;
	}

	.boxRight {
		padding: 15px;
		border: 2px solid;
		border-image: linear-gradient(to right, #7094d36b, #7094d3) 1;
	}

	.text-bold {
		font-weight: 700;
	}

	.text-lg {
		/* font-weight: 700; */
	}

	.text-indent {
		margin-left: 2em;
	}

	.text-center {
		text-align: center;
	}
</style>
