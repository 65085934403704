<template>
	<div>
		<nav2></nav2>
		<banner2  :bannerImg="Img1" :one="t('title')"></banner2>
		<div class="fixed-width-container container row" style="margin-top: 50px;">
			<div class="col-12">
				<h1 style="text-align: center;" class="display-6">{{t("title")}}</h1>
			</div>
			<div style="margin-top: 35px" class="row">
				<div>
					<p class="text-bold">
						{{t("h1_t")}}
					</p>
					<p class="text-bold">
						{{t("h2_t1")}}
					</p>
					<p class="">
						{{t("h2_t2")}}
					</p>
					
					<p class="">
						{{t("h2_t3")}}
					</p>
					<p class="">
						{{t("h2_t4")}}
					</p>
					
					
					<p class="text-bold">
						{{t("h3_t1")}}
					</p>
					<p class="">
						{{t("h3_t2")}}
					</p>
					
					<p class="">
						{{t("h3_t3")}}
					</p>
					<p class="">
						{{t("h3_t4")}}
					</p>
					
					
					<p class="text-bold">
						{{t("h4_t1")}}
					</p>
					<p class="">
						{{t("h4_t2")}}
					</p>
					
					<p class="">
						{{t("h4_t3")}}
					</p>
					<p class="">
						{{t("h4_t4")}}
					</p>
					
					<p class="text-bold">
						{{t("h5_t1")}}
					</p>
					<p class="">
						{{t("h5_t2")}}
					</p>
					
					<p class="">
						{{t("h5_t3")}}
					</p>
					<p class="">
						{{t("h5_t4")}}
					</p>
					
					
				</div>
				<div style="margin-bottom: 25px;">
					<p style="text-align: center"><img src="../../assets/images/AnLi/5-1.png" height="450"
							width="800" /></p>
				</div>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script setup>
	import footer2 from "../../components/footer.vue";
	import banner2 from "../../components/banner.vue";
	import nav2 from "../../components/nav.vue";

	export default {
		name: "index",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
		},
		data() {
			return {
				Img1: require("../../assets/img/keji/矿山banner1.png"),
				content: {
					title: {
						zh: "海外红人客户案例",
						en: "Overseas Reddit Client Cases"
					},
					h1_t: {
						zh: "海外KOL（Key Opinion Leader，关键意见领袖）在品牌推广和客户营销中扮演着越来越重要的角色。以下是一些成功的海外KOL客户案例：",
						en: "Overseas KOL (Key Opinion Leader) plays an increasingly important role in brand promotion and customer marketing. Below are some examples of successful overseas KOL clients:"
					},
					h2_t1: {
						zh: "Daniel Wellington与Instagram KOL的合作：",
						en: "Background information"
					},
					h2_t2: {
						zh: "Daniel Wellington（DW）是一家瑞典手表品牌，以简约时尚的设计风格闻名。",
						en: "Daniel Wellington (DW) is a Swedish watch brand known for its minimalist and stylish designs."
					},
					h2_t3: {
						zh: "DW与Instagram上的KOL @xeniaadonts合作，这位KOL拥有数百万粉丝，并与DW合作发布了一系列精美的照片，展示了手表的时尚和高贵气质。",
						en: "DW has partnered with Instagram KOL @xeniaadonts, who has millions of followers and has collaborated with DW to post a series of beautiful photographs showcasing the watch's stylish and regal aura."
					},
					h2_t4: {
						zh: "通过这次合作，DW成功吸引了大量粉丝的关注，提升了品牌的知名度和销量。",
						en: "Through this collaboration, DW has managed to attract the attention of a large number of fans and increase brand awareness and sales."
					},


					h3_t1: {
						zh: "Huda Kattan的美妆品牌推广：",
						en: "Huda Kattan's beauty branding:"
					},
					h3_t2: {
						zh: "Huda Kattan是一位在美妆领域有着广泛影响力的海外KOL，她通过自己的博客和YouTube频道分享美妆技巧和产品推荐。",
						en: "Huda Kattan is an overseas KOL with a wide range of influence in the beauty field, sharing beauty tips and product recommendations through her blog and YouTube channel."
					},
					h3_t3: {
						zh: "她经常亲自试用产品，并给出真实的评价，这使得她的粉丝非常信任她的推荐。",
						en: "She often tries products herself and gives honest reviews, which makes her fans trust her recommendations."
					},
					h3_t4: {
						zh: "Huda Kattan还创办了自己的美妆品牌Huda Beauty，并通过与其他KOL的合作推广，取得了巨大的成功。",
						en: "Huda Kattan also started her own beauty brand, Huda Beauty, and has had great success by promoting it in partnership with other KOLs."
					},

					h4_t1: {
						zh: "Chiara Ferragni的时尚影响力：",
						en: "Chiara Ferragni's fashion influences:"
					},
					h4_t2: {
						zh: "Chiara Ferragni是一位意大利时尚博主和设计师，在社交媒体上拥有数百万的粉丝。",
						en: "Chiara Ferragni is an Italian fashion blogger and designer with millions of followers on social media."
					},
					h4_t3: {
						zh: "她以独特的时尚品味和影响力，成功推广了多个时尚品牌和设计师的作品。",
						en: "With her unique fashion taste and influence, she has successfully promoted several fashion brands and designers."
					},
					h4_t4: {
						zh: "她的影响力不仅限于时尚领域，还涉及到了其他领域，如生活方式、旅游和美食等。",
						en: "Her influence is not only limited to fashion, but also to other areas such as lifestyle, travel and food."
					},
					h5_t1: {
						zh: "某国际旅游公司希望在欧洲市场推广其度假目的地，选择了与在旅游领域有广泛影响力的海外KOL合作。",
						en: "An international travel company looking to promote its vacation destinations in the European market chose to partner with an overseas KOL with extensive influence in the travel space."
					},
					h5_t2: {
						zh: "这位KOL被邀请前往旅游公司的度假目的地进行体验，并在社交媒体上分享自己的旅行故事和推荐。",
						en: "The KOL was invited to travel to the travel company's vacation destinations to experience them and share his travel stories and recommendations on social media."
					},
					h5_t3: {
						zh: "通过KOL的推荐和分享，该旅游公司的度假目的地得到了广泛的曝光和认可，吸引了大量游客前往。",
						en: "Through KOL recommendations and sharing, the travel company's vacation destinations received widespread exposure and recognition, attracting a large number of tourists to them."
					},
					h5_t4: {
						zh: "这些案例展示了海外KOL在不同领域的推广效果和影响力。成功的海外KOL推广背后有着精心设计的策略，包括选择合适的KOL、确保内容创作与受众期待相符合、保持社交互动等。同时，品牌还需要考虑跨文化传播和市场定位的因素，以确保推广策略适应当地受众的需求。",
						en: "These cases demonstrate the effectiveness and influence of overseas KOL promotion in different fields. Successful overseas KOL promotion has a well-designed strategy behind it, including choosing the right KOLs, ensuring that content creation matches audience expectations, and maintaining social interactions. At the same time, brands also need to consider cross-cultural communication and market positioning factors to ensure that the promotion strategy adapts to the needs of local audiences."
					},

				},
				language: "en",

			};
		},
		created() {
			this.language = this.$i18n.getLocale();
		},
		methods: {
			t(res) {

				return this.content[res][this.language];
			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.fixed-width-container {
		width: 800px !important;
		/* 设置固定宽度 */
		max-width: 100%;
		/* 确保在小屏幕上宽度占满容器 */
		margin: 0 auto;
		/* 水平居中 */
	}
	p{
		margin-top: 10px;
	}
	
	p.text-bold{
		margin-top: 25px;
	}
</style>
