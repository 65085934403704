<template>
	<div class="row" style="background: #17213d;">
		<div class="row fixed-width-container container" style="padding-top: 30px;">
			<!-- <div class="oneLi col-12" style="margin: 10px;">
        <a style="color: white" @click="Clj()">Top <span style="text-decoration: underline">⋀</span></a>
      </div> -->
			<!--      下 信息-->
			<div class="row" style="color:white;background-color:#17213d; ">
				<div class="col-sm-7" style="margin-left: 10px;">
					<dl class="row col-12">
						<dd class="col-sm-3">{{$i18n.t('Address_1')}}:</dd>
						<dd class="col-sm-9">{{$i18n.t('Address_2')}}</dd>
						<dd class="col-sm-3">{{$i18n.t('yx2')}}:</dd>
						<dd class="col-sm-9">info@eupmeida.com</dd>
						<dd class="col-sm-3">{{$i18n.t('yx1')}}: </dd>
						<dd class="col-sm-9">info@eupmeida.com</dd>
						<dd class="col-sm-3 text-truncate">{{$i18n.t('number')}}:</dd>
						<dd class="col-sm-9">4009613228</dd>
					</dl>
				</div>
				<div class="col-sm-5 row text-center">
					<p class="col-12" style="text-align: center;color: white;padding-bottom: 5px">{{$i18n.t('WxGzh')}}
					</p>
					<div class="col-sm-6" style="width:150px;margin: auto;">
						<p style="text-align: center;"><img src="../assets/images/ylszx.png" height="100" width="100" />
						</p>
						<p style="text-align: center;">{{$i18n.t('eup1')}}</p>
					</div>
					<div class="col-sm-6" style="width:150px;margin: auto;">
						<p style="text-align: center;"><img src="../assets/images/ylssb.png" height="100" width="100" />
						</p>
						<p style="text-align: center;">{{$i18n.t('eup2')}}</p>
					</div>
				</div>
			</div>
			<div class="col-12">
				<p style="font-size: 12px;color: rgba(255,255,255,0.50);margin-top: 10px;padding-left: 10px;">Copyright@
					EupWire. <a rel="nofollow" style="color: rgba(255,255,255,0.50);margin-left: 40px;" href="http://beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank">沪ICP备2022013931号-3</a></p>
					
					
					
					
					
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer",
		data() {
			return {
				// currentLang: "zh",
				logoImg: require("../assets/images/logo-2.png"),
			};
		},

		mounted() {
			let _this = this;
			_this.$nextTick(function() {
				window.addEventListener("scroll", _this.handleScroll);
			});
		},

		methods: {
			// 锚点跳转
			toGuid(path, id) {
				localStorage.setItem("toId", id);
				this.$router.push(path);
				//查找存储的锚点id
				let Id = localStorage.getItem("toId");
				let toElement = document.getElementById(Id);
				//锚点存在跳转
				if (Id) {
					toElement.scrollIntoView();
					localStorage.setItem("toId", "");
				}
			},
			Clj() {
				window.scrollTo(0, 0); // 滚动到页面顶部
			},
			funyuming(msg) {
				this.$router.push(msg);
			},
			toPages(url) {
				this.$router.push(url);
			},
			toNews(url) {
				this.$router.push(url);
			},
		},
	};
</script>
<style>
	* {
		margin: 0;
		padding: 0;
	}

	/*去ul li 小点*/
	ul {
		list-style: none;
	}

	/*去超链接下划线*/
	a {
		text-decoration: none;
	}

	/*img IE6 兼容问题*/
	img {
		border: 0 none;
	}

	.fixed-width-container {
		width: 1200px !important;
		/* 设置固定宽度 */
		max-width: 100%;
		/* 确保在小屏幕上宽度占满容器 */
		margin: 0 auto;
		/* 水平居中 */
	}

	input,
	select,
	textarea {
		outline: 0;
	}
</style>
