<template>
	<div>
		<nav2></nav2>
		<banner2 :bannerImg="Img1" :one="t('title')"></banner2>
		<div class="fixed-width-container container row" style="margin-top: 50px;">
			<div class="col-12">
				<h1 style="text-align: center;" class="display-6">{{t("title")}}</h1>
			</div>
			<div style="margin-top: 35px" class="row">
				<div>

					<p class="text-bold">
						{{t("h1_t1")}}
					</p>
					<p class="">
						{{t("h1_t2")}}
					</p>
					<p class="">
						{{t("h1_t3")}}
					</p>
					
					
					<p class="text-bold">
						{{t("h2_t1")}}
					</p>
					<p class="">
						{{t("h2_t2")}}
					</p>
					
					
					
					
					<p class="text-bold">
						{{t("h3_t1")}}
					</p>
					<p class="">
						{{t("h3_t2")}}
					</p>
					<p class="">
						{{t("h3_t3")}}
					</p>
					<p class="">
						{{t("h3_t4")}}
					</p>
					<p class="">
						{{t("h3_t5")}}
					</p>
					<p class="">
						{{t("h3_t6")}}
					</p>
					
					
					
					
					<p class="text-bold">
						{{t("h4_t1")}}
					</p>
					<p class="">
						{{t("h4_t2")}}
					</p>
					<p class="">
						{{t("h4_t3")}}
					</p>
					<p class="">
						{{t("h4_t4")}}
					</p>
					<p class="">
						{{t("h4_t5")}}
					</p>
					
					
					<p class="text-bold">
						{{t("h51_t1")}}
					</p>
					<p class="">
						{{t("h51_t2")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-2.png" width="800" />
					</p>
					
					<p class="text-bold text-center">
						{{t("h5_t1")}}
					</p>
					<p class="text-center">
						{{t("h5_t2")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-3.png" width="800" />
					</p>

					<p class="text-bold text-center">
						{{t("h6_t1")}}
					</p>
					<p class="text-center">
						{{t("h6_t2")}}
					</p>
					
					<p class="text-bold ">
						{{t("h7_t1")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-4.png" width="800" />
					</p>
					<p class=" ">
						{{t("h7_t2")}}
					</p>
					
					
					
					<p class="text-bold ">
						{{t("h8_t1")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-5.jpg" width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-6.png" width="800" />
					</p>
					<p class=" text-bold">
						{{t("h9_t1")}}
					</p>
					<p class="text-bold ">
						{{t("h9_t2")}}
					</p>
					
					<p class=" ">
						{{t("h10_t1")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-7.png" width="800" />
					</p>

					<p class="">
						{{t("h10_t2")}}
					</p>
					<p class="text-bold ">
						{{t("h11_t1")}}
					</p>
					
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-8.jpg" width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-9.jpg" width="800" />
					</p>
					<p class=" ">
						{{t("h11_t2")}}
					</p>
					
					<p class=" text-bold">
						{{t("h12_t1")}}
					</p>
					<p class=" ">
						{{t("h12_t2")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-11.png" width="800" />
					</p>
					
					<p class="">
						{{t("h13_t1")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-12.png" width="800" />
					</p>
					
					
					<p class=" text-bold">
						{{t("h14_t1")}}
					</p>
					<p class="text-bold " style="margin-top: 0;">
						{{t("h15_t1")}}
					</p>
					
					
					<p class=" text-bold">
						{{t("h16_t1")}}
					</p>
					<p class=" ">
						{{t("h16_t2")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-13.png" width="800" />
					</p>
					
					<p class=" ">
						{{t("h16_t3")}}
					</p>
					
					<p class="text-bold text-center ">
						{{t("h16_t4")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-14.png" width="800" />
					</p>
					
					<p class="text-bold  ">
						{{t("h16_t5")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-15.png" width="800" />
					</p>
					
					<p class="  ">
						{{t("h16_t6")}}
					</p>
					<p class="text-bold  ">
						{{t("h17_t1")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-16.png" width="800" />
					</p>
					
					<p class="  ">
						{{t("h17_t2")}}
					</p>
					<p class="  ">
						{{t("h17_t3")}}
					</p>
					<p class="  ">
						{{t("h17_t4")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-17.jpg" width="800" />
					</p>
					<p class="text-bold  ">
						{{t("h18_t1")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-18.png" width="800" />
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-19.png" width="800" />
					</p>
					<p class="text-bold  ">
						{{t("h18_t2")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-20.jpg" width="800" />
					</p>
					<p class="text-bold text-center ">
						{{t("h18_t3")}}
					</p>
					<p class="text-bold  ">
						{{t("h18_t4")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-21.jpg" width="800" />
					</p>
					<p class="text-bold ">
						{{t("h18_t5")}}
					</p>
					
					<p class="text-bold ">
						{{t("h18_t6")}}
					</p>
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-22.jpg" width="800" />
					</p>
					<p class="text-bold ">
						{{t("h18_t7")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-23.jpg" width="800" />
					</p>
					<p class="text-bold ">
						{{t("h18_t8")}}
					</p>
					<p class="text-bold ">
						{{t("h18_t9")}}
					</p>
					
					<p style="margin-bottom: 25px;text-align: center;">
						<img src="../../assets/images/AnLi/1-24.png" width="800" />
					</p>
				</div>
			</div>
		</div>
		<div style="padding: 25px;"></div>
		<footer2></footer2>
	</div>
</template>

<script setup>
	import footer2 from "../../components/footer.vue";
	import banner2 from "../../components/banner.vue";
	import nav2 from "../../components/nav.vue";

	export default {
		name: "index",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
		},
		data() {
			return {
				Img1: require("../../assets/img/keji/矿山banner1.png"),
				content: {
					title: {
						zh: "广汽GAC出海，打响世界",
						en: "GAC goes to sea and hits the world"
					},
					h1_t1: {
						zh: "客户需求：",
						en: "client need:",
					},
					h1_t2: {
						zh: "1.	推动GS4全球销售",
						en: "1. Promoting global sales of the GS4",
					},
					h1_t3: {
						zh: "2.	提升GAC全球品牌形象",
						en: "2. Enhancement of GAC's global brand image",
					},
					
					
					
					
					h2_t1: {
						zh: "公司传播使用的基本策略：",
						en: "Basic strategies used in corporate communications:",
					},
					h2_t2: {
						zh: "以无人驾驶概念车作为引爆点吸引媒体、受众关注；将话题与谷歌无人驾驶汽车、特斯拉电动车做适度捆绑，同时把GAC品牌及GS4的信 息充分融入在对外释放的信息中。",
						en: "The driverless concept car was used as a trigger to attract media and audience attention; the topic was moderately bundled with Google's driverless car and Tesla's electric car, and at the same time, the GAC brand and the GS4's message were fully integrated into the information released to the public.",
					},
					
					
					
					
					h3_t1: {
						zh: "传播要点",
						en: "Points for dissemination",
					},
					h3_t2: {
						zh: "1.	新车资讯（GS4以及WitStar）",
						en: "1. New vehicle information (GS4 and WitStar)",
					},
					h3_t3: {
						zh: "2.	集团海外战略",
						en: "2. Group overseas strategy",
					},
					h3_t4: {
						zh: "3.	GAC代表中国最高质量水平",
						en: "3. GAC represents the highest level of quality in China",
					},
					h3_t5: {
						zh: "4.	GAC增长、扩张之路",
						en: "4. GAC's path to growth, expansion",
					},
					h3_t6: {
						zh: "5.	科研投入，完整体系，6大突破介绍",
						en: "5. Scientific research investment, complete system, 6 major breakthroughs Introduction",
					},
					
					
					h4_t1: {
						zh: "主要成果",
						en: "Main results",
					},
					h4_t2: {
						zh: "1.	内容在谷歌相关搜索中，占据首页70%以上篇幅",
						en: "1. The content occupies more than 70% of the first page in Google relevant searches",
					},
					h4_t3: {
						zh: "2.	5千家媒体转发通稿；38家外媒刊发专访",
						en: "2. 5,000 media outlets reprinted the press release; 38 foreign media outlets published an exclusive interview",
					},
					h4_t4: {
						zh: "3.	全球网络关注度提升近2倍，达成更多国际经销商合作",
						en: "3. Nearly two-fold increase in global network attention and more international distributor cooperation",
					},
					h4_t5: {
						zh: "4.	内容在国内引发热传；新车型引发更大关注",
						en: "4. Content generates buzz in the country; new models generate more attention",
					},
					
					
					h51_t1: {
						zh: "译联社提供服务：",
						en: "EUP provision of services:",
					},
					h51_t2: {
						zh: "新闻通稿",
						en: "press release",
					},
					
					
					
					h5_t1: {
						zh: "雅虎刊登",
						en: "Posted by Yahoo!",
					},
					h5_t2: {
						zh: "GAC在底特律展示全新GS4，加速全球化进程",
						en: "GAC showcases new GS4 in Detroit to accelerate globalization",
					},
					
					
					
					
					
					
					
					
					
					h6_t1: {
						zh: "路透刊登",
						en: "published by Reuters",
					},
					h6_t2: {
						zh: "WITSTAR秀出GAC的研发肌肉",
						en: "WITSTAR shows off GAC's R&D muscle",
					},
					
					
					h7_t1: {
						zh: "取得的效果",
						en: "Results achieved",
					},
					h7_t2: {
						zh: "新闻通稿良好地覆盖了一系列相关的关键词，使目标受众的自然搜索能第一时间到达GAC主动发布的内容。新闻通稿同时覆盖全球相关行业记者，直接为GAC带来多次专访报道及展会现场采访的机会。",
						en: "The press release covered a range of relevant keywords so that the target audience's natural search would reach GAC's proactive content in the first place. The press release also reached relevant industry journalists around the world, directly bringing GAC multiple interviews and on-site interview opportunities.",
					},
					
					
					h8_t1: {
						zh: "多媒体新闻",
						en: "multimedia news",
					},
					
					
					
					h9_t1: {
						zh: "广汽在底特律首秀",
						en: "GAC makes its debut in Detroit",
					},
					h9_t2: {
						zh: "力争打造世界级汽车品牌",
						en: "Striving to build a world-class automotive brand",
					},
					
					h10_t1: {
						zh: "取得的效果",
						en: "Results achieved",
					},
					h10_t2: {
						zh: "多媒体新闻将广汽近期推广的多媒体内容整合在一个落地页面中，让目标受众及媒体一站式获取所有内容。 多媒体新闻落地页面本身对搜索引擎十分友好，在相关关键词搜索中能取得较高排名。 多媒体素材到达视频网站、社交网站后，也为相关品牌、产品信息的传播打下基础。",
						en: "Multimedia News integrates BAIC's recent promotional multimedia content into one landing page, allowing target audiences and media to access all the content in one stop. The multimedia news landing page itself is very friendly to search engines and can achieve high rankings in relevant keyword searches. After the multimedia material reaches video sites and social networking sites, it also lays the foundation for the dissemination of related brand and product information.",
					},
					
					
					h11_t1: {
						zh: "外媒专访",
						en: "foreign news media interview",
					},
					h11_t2: {
						zh: "外媒独立报道成稿38篇，其中仅美联社的报道既被转发超过250次。",
						en: "Thirty-eight independent foreign media reports were produced, including one by the Associated Press alone that was retweeted more than 250 times.",
					},
					
					
					
					h12_t1: {
						zh: "多媒体新闻和外媒专访取得的成果",
						en: "Results achieved in multimedia news and media interviews",
					},
					h12_t2: {
						zh: "主推车型google搜索： gac gs4",
						en: "Main model google search: gac gs4",
					},
					
					
					h13_t1: {
						zh: "通过通稿和专访报道，搜索结果首页达成全面的覆盖",
						en: "Full coverage on the first page of search results through press releases and interviews",
					},
					
					
					h14_t1: {
						zh: "互动反馈",
						en: "Interactive feedback",
					},
					h15_t1: {
						zh: "内容策划",
						en: "content planning",
					},
					
					
					h16_t1: {
						zh: "服务结果",
						en: "Service results",
					},
					h16_t2: {
						zh: "谷歌趋势：“gac motor”",
						en: "Google Trends: \"gac motor\"",
					},
					h16_t3: {
						zh: "广汽品牌关注度提升近2倍",
						en: "GAC Brand Attention Increases Nearly Twice",
					},
					h16_t4: {
						zh: "传播节奏与效果",
						en: "Rhythm and effectiveness of communication",
					},
					h16_t5: {
						zh: "发布概览",
						en: "Publishing Overview",
					},
					h16_t6: {
						zh: "新闻通稿良好地覆盖了一系列相关的关键词，使目标受众的自然搜索能第一时间到达TIENS主动发布的内容。 新闻通稿同时覆盖全球相关行业记者，直接为TIENS带来多次专访报道及展会现场采访的机会。",
						en: "The press release covered a range of relevant keywords well, so that natural searches of the target audience would reach TIENS' proactive content in the first place. The press release also reached relevant industry journalists around the world, which directly led to numerous interviews and on-site interviews for TIENS.",
					},
					
					
					h17_t1: {
						zh: "媒体覆盖范围- GLOBAL MEDIA COVERAGE",
						en: "Media Coverage- GLOBAL MEDIA COVERAGE",
					},
					h17_t2: {
						zh: "1、篇稿件发布自36个国家与地区， 覆盖东亚、 西亚、东南亚、东非、中欧、西欧、美洲等地 区，基本实现全球发布",
						en: "1. Articles are published from 36 countries and regions, covering East Asia, West Asia, Southeast Asia, East Africa, Central Europe, Western Europe, the Americas and other regions, basically realizing global publication.",
					},
					h17_t3: {
						zh: "2、覆盖21种不同语种人群",
						en: "2. Covering 21 different language groups",
					},
					h17_t4: {
						zh: "3、预计触达45亿人次，平均覆盖人群4600万",
						en: "3. Expected to reach 4.5 billion people, with an average coverage of 46 million people",
					},
					
					h18_t1: {
						zh: "搜索引擎露出效果",
						en: "Search Engine Exposure",
					},
					
					h18_t2: {
						zh: "新闻通稿发布——权威媒体",
						en: "Press release distribution - authoritative media",
					},
					h18_t3: {
						zh: "图：美联社英国刊登",
						en: "Photo: Published by Associated Press UK",
					},
					h18_t4: {
						zh: "美联社德、英、法、巴等多家站点均已发布",
						en: "The Associated Press has been released by a number of sites in Germany, England, France and Pakistan",
					},
					h18_t5: {
						zh: "雅虎新闻（德国）刊登",
						en: "Published by Yahoo News (Germany)",
					},
					h18_t6: {
						zh: "雅虎英、法、德、巴、意等国家均已刊登",
						en: "Yahoo! English, French, German, Pakistani, Italian and other countries have been published",
					},
					
					h18_t7: {
						zh: "法新社 巴西",
						en: "AFP Brazil",
					},
					h18_t8: {
						zh: "美联社 巴西",
						en: "Associated Press Brazil",
					},
					h18_t9: {
						zh: "传播目标与受众精准匹配，以专业引领行业",
						en: "Accurately matching communication goals and audiences, leading the industry with professionalism",
					},
					
				},
				language: "en",

			};
		},
		created() {
			this.language = this.$i18n.getLocale();
		},
		methods: {
			t(res) {
				return this.content[res][this.language];
			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.fixed-width-container {
		width: 800px !important;
		/* 设置固定宽度 */
		max-width: 100%;
		/* 确保在小屏幕上宽度占满容器 */
		margin: 0 auto;
		/* 水平居中 */
	}

	p {
		margin-top: 10px;
	}

	p.text-bold {
		margin-top: 25px;
	}
</style>
